import { gql, useMutation } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import PushWidget from "../components/widget/PushWidget";
import { UserLoginContext } from "../context/UserLoginContext";
import useEventInfo from "../hooks/useEventInfo";
import useSession from "../hooks/useSession";
import useStamp from "../hooks/useStamp";
import { useTimeWorker } from "../hooks/useTimeWorker";
import Conference from "../pages/conference/Conference";
import IndoorExhibitionContainer from "../pages/indoor/IndoorExhibition";
import Lobby2DStyle1 from "../pages/lobby/Lobby2DStyle1";
import Lobby2DStyle2 from "../pages/lobby/Lobby2DStyle2";
import Lobby2DStyle3 from "../pages/lobby/Lobby2DStyle3";
import Lobby2DStyle4 from "../pages/lobby/Lobby2DStyle4";
import { addStatisticVisit } from "../query/queries";
import ChannelService from "../util/ChannelService";
import SeoulNTower from "./../pages/booth/SeoulNTower";
import Event from "./common/modal/contents/Event";
import ProgramInfo from "./common/modal/contents/programInfo/ProgramInfo";
import SessionReplay from "./common/modal/contents/SessionReplay";
import VideoOnly from "./common/modal/contents/video/VideoOnly";
import BoothContainer from "./exhibition/BoothContainer";
import MemberShip2D from "./lounge/MemberShip2D";
import Navbar from "./navbar/Navbar";
import TheaterVodList from "./theater/TheaterVodList";
import VOD from "./theater/VOD";
import { SocketContext } from "../context/SocketContext";
import { ModalPortalContext } from "../context/ModalContext";
import Survey from "./survey/Survey.jsx";
import AccountRegistration from "./common/modal/contents/accountRegistration/AccountRegistration";
import UserPolicy from "./common/modal/contents/registration/UserPolicy";

const ADD_STATISTIC_VISIT = gql`
  ${addStatisticVisit}
`;

function App() {
  const location = useLocation();
  const [addStatisticVisit] = useMutation(ADD_STATISTIC_VISIT);
  const {
    eventInfo,
    dimension,
    eventInfoCompleted,
    error,
    refetchEventInfo,
    dimensionTransform,
  } = useEventInfo();
  const {
    lobbySet,
    time_zone: timeZone,
    scheduleList,
    isLang: mainLang,
    replayList,
    eventActive,
  } = eventInfo;
  const qs = new URLSearchParams(window.location.search);

  const lobbyStyle = lobbySet?.custom_code;
  const { getToken, getMyInfo, showLoginModal, setIsLogin, getUser } =
    useContext(UserLoginContext);
  const user = getUser();
  const worker = useTimeWorker();
  const { socket, setCurrentRoom } = useContext(SocketContext);
  const { triggerModal, openAlert, close } = useContext(ModalPortalContext);

  const {
    selectedSession,
    selectedPT,
    isUseSessionLoading,
    setSelectedSession,
  } = useSession({
    scheduleList: eventInfo.scheduleList || [],
    eventInfoCompleted,
    worker,
    mainLang: eventInfo.isLang,
  });
  const { setStamp } = useStamp(eventInfo.type);
  // const [mainScreen, setMainScreen] = useState("");
  const theaterCategoryList = eventInfo?.theaterSet?.theaterCategoryList;

  React.useEffect(() => {
    if (eventActive?.all_active === "N") {
      openAlert({
        bool: true,
        msg: eventActive?.all_inactive_msg,
        disableCloseAlert: true,
      });
    }
  }, [eventActive]);

  React.useEffect(() => {
    if (
      eventInfo.navigationSet?.channeltalk_key &&
      Object.keys(user).length > 0
    ) {
      ChannelService.boot({
        pluginKey: eventInfo.navigationSet.channeltalk_key, //please fill with your plugin key
        profile: {
          name: user.name, // agent 화면에서 조회 / 수정할 수 있음. 유료.
          mobileNumber: user.mobile_no, //fill with user phone number
          CUSTOM_VALUE_1: user.email, //any other custom meta data
          CUSTOM_VALUE_2: user.title,
        },
      });
    }
  }, [eventInfo.navigationSet, user]);

  const play = (categoryName = "", videoInfo, videoList) => {
    setStamp("theater_vod");
    triggerModal({
      bool: true,
      props: {
        onPrev: showFuncCollection.showVod,
        // enabledMeshes.SeoulTheater_Screen01.callback(
        //   categoryName,
        //   videoList
        // ),
        play,
        videoInfo,
        videoList,
      },
      content: {
        title: categoryName,
        Component: (props) => {
          return <VOD {...props} />;
        },
        hasOwnContainer: true,
      },
    });
  };

  React.useEffect(() => {
    if (
      Object.keys(user).length > 0 &&
      eventInfo.eventActive.event_banner_active === "Y"
    ) {
      showFuncCollection.showEvent(eventInfo.eventBannerSet);
    }
  }, [user]);

  const showFuncCollection = {
    showEvent: (eventBannerSet) => {
      triggerModal({
        bool: true,
        props: {
          eventBannerSet,
          close,
        },
        content: {
          title: "",
          Component: (props) => {
            return <Event {...props} />;
          },
          hasOwnContainer: true,
        },
      });
    },
    showSurvey: (url, type) => {
      triggerModal({
        bool: true,
        props: {
          url,
          close,
          type,
        },
        content: {
          title: "",
          Component: (props) => {
            return <Survey {...props} />;
          },
          hasOwnContainer: true,
        },
      });
    },
    showProgramInfo: () => {
      triggerModal({
        bool: true,
        props: {
          scheduleList,
          user,
          pt_survey_active: eventActive?.pt_survey_active,
        },
        content: {
          title: "PROGRAM INFO",
          Component: (props) => {
            return <ProgramInfo {...props} />;
          },
          hasOwnContainer: true,
        },
        hasPrevButton: false,
      });
    },
    showReplay: () => {
      triggerModal({
        bool: true,
        props: {
          replayList,
          setStamp: () => setStamp("conference_replay"),
          close,
        },
        content: {
          title: "SESSION REPLAY",
          Component: (props) => {
            return (
              <SessionReplay {...props}>
                <VideoOnly />
              </SessionReplay>
            );
          },
          hasOwnContainer: true,
        },
        hasPrevButton: false,
      });
    },
    showVod: () => {
      triggerModal({
        bool: true,
        props: {
          categoryList: theaterCategoryList,
          play,
          close,
        },
        content: {
          title: "VOD",
          Component: (props) => {
            return <TheaterVodList {...props} />;
          },
          hasOwnContainer: true,
        },
        hasPrevButton: false,
      });
    },
    showNetworking: () => {
      triggerModal({
        bool: true,
        props: {
          user,
          socket,
          setCurrentRoom,
          openAlert,
          close,
        },
        content: {
          title: "1:1 Mettings with Delegates",
          Component: (props) => {
            return <NetworkLoungeTable {...props} />;
          },
          hasOwnContainer: true,
        },
        hasPrevButton: false,
      });
    },
    showMembership: () => {
      triggerModal({
        bool: true,
        props: {},
        content: {
          title: "Create your membership",
          Component: (props) => {
            return <MemberShip2D {...props} />;
          },
          hasOwnContainer: true,
        },
        hasPrevButton: false,
      });
    },
    goTo: (url) => window.open(url, "_blank"),
  };

  useEffect(() => {
    addStatisticVisit();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      localStorage.setItem("prevExhibitionHallNum", "0");
    });
  }, []);

  // useEffect(() => {
  //   console.log("selectedPT", selectedPT);
  // }, [selectedPT]);

  useEffect(() => {
    const showRegistration = () => {
      triggerModal({
        bool: true,
        props: {
          onPrev: () => {
            showLoginModal(eventInfo.type, eventInfo.login_logo);
          },
          openAlert,
        },
        content: {
          title: "Registration",
          Component: (props) => {
            return (
              <AccountRegistration {...props}>
                <UserPolicy />
              </AccountRegistration>
            );
          },
          hasOwnContainer: true,
        },
        hasPrevButton: true,
      });
    };

    if (
      (eventInfo.type === "unjoin" || eventInfo.type === "join") &&
      eventInfoCompleted
    ) {
      if (!getToken()) {
        const registerOpen = qs.get("register");
        if (registerOpen === "1") {
          showRegistration();
        } else {
          showLoginModal(eventInfo.type, eventInfo.login_logo);
        }
      } else {
        setIsLogin(true);
        getMyInfo();
      }
    }
  }, [eventInfo.type, eventInfoCompleted, location.pathname]);

  return (
    <>
      {eventInfoCompleted && (
        <>
          <div className="FrontApp">
            <Navbar
              loginType={eventInfo.type || ""}
              loginLogo={eventInfo.login_logo || ""}
              mainLang={eventInfo.isLang || ""}
              dimension={dimension}
              dimensionTransformActive={eventInfo.dimension_transform_active}
              dimensionTransform={dimensionTransform}
              timeZone={eventInfo.time_zone || ""}
              languages={eventInfo.language || []}
              privacy_consent={eventInfo.privacy_consent}
              user_consent={eventInfo.user_consent}
              navigationSet={eventInfo.navigationSet}
              scheduleList={eventInfo.scheduleList}
              setSelectedSession={setSelectedSession}
              refetchEventInfo={refetchEventInfo}
              selectedPT={selectedPT}
              replayList={eventInfo.replayList}
              setStamp={setStamp}
              socket={socket}
              setCurrentRoom={setCurrentRoom}
              theaterCategoryList={theaterCategoryList}
              showFuncCollection={showFuncCollection}
            />
          </div>
          <PushWidget />
        </>
      )}

      <Switch>
        <Route
          exact
          path="/exhibition/booth/:id"
          render={() => {
            return (
              <BoothContainer
                loginType={eventInfo.type || ""}
                setStamp={setStamp}
              />
            );
          }}
        ></Route>
        <Route
          exact
          path="/exhibition/indoor"
          render={() => {
            return (
              <IndoorExhibitionContainer
                eventInfoCompleted={eventInfoCompleted}
                error={error}
                mainLang={eventInfo.isLang || ""}
                indoorExhibitionSet={eventInfo.exhibitionSet || {}}
                setStamp={setStamp}
                selectedPT={selectedPT || {}}
              />
            );
          }}
        ></Route>

        <Route
          exact
          path="/booth"
          render={() => {
            return (
              <SeoulNTower
                eventInfoCompleted={eventInfoCompleted}
                error={error}
                seoulBoothSet={eventInfo.seoulBoothSet || {}}
                channelTalk={{
                  key: eventInfo.navigationSet?.channeltalk_key,
                  yn: eventInfo.navigationSet?.channeltalk_yn,
                }}
                setStamp={setStamp}
              />
            );
          }}
        ></Route>

        <Route
          exact
          path="/conference"
          render={() => {
            return (
              <Conference
                pt_survey_active={eventActive?.pt_survey_active}
                loginType={eventInfo.type || ""}
                eventInfoCompleted={eventInfoCompleted}
                isUseSessionLoading={isUseSessionLoading}
                mainLang={eventInfo.isLang || ""}
                error={error}
                selectedSession={selectedSession || {}}
                selectedPT={selectedPT || {}}
                replayList={eventInfo.replayList || []}
                speakerList={eventInfo.speakerList || []}
                setStamp={setStamp}
                showFuncCollection={showFuncCollection}
                timeZone={eventInfo.time_zone || ""}
                scheduleList={eventInfo.scheduleList}
              />
            );
          }}
        ></Route>

        <Route
          exact
          path="/"
          render={() => {
            if (lobbyStyle === "LOBBY_2D_01") {
              return (
                <Lobby2DStyle1
                  isLang={eventInfo.isLang}
                  loginType={eventInfo.loginType}
                  names={
                    eventInfo.navigationSet ? eventInfo.navigationSet.names : {}
                  }
                  error={error}
                  lobbySet={eventInfo.lobbySet || {}}
                  eventInfoCompleted={eventInfoCompleted}
                  setSelectedSession={setSelectedSession}
                  setStamp={setStamp}
                  selectedPT={selectedPT || {}}
                  showFuncCollection={showFuncCollection}
                />
              );
            }
            if (lobbyStyle === "LOBBY_2D_02") {
              return (
                <Lobby2DStyle2
                  isLang={eventInfo.isLang}
                  loginType={eventInfo.loginType}
                  names={
                    eventInfo.navigationSet ? eventInfo.navigationSet.names : {}
                  }
                  error={error}
                  lobbySet={eventInfo.lobbySet || {}}
                  eventInfoCompleted={eventInfoCompleted}
                  setSelectedSession={setSelectedSession}
                  setStamp={setStamp}
                  selectedPT={selectedPT || {}}
                  showFuncCollection={showFuncCollection}
                />
              );
            }
            if (lobbyStyle === "LOBBY_2D_03") {
              return (
                <Lobby2DStyle3
                  isLang={eventInfo.isLang}
                  loginType={eventInfo.loginType}
                  names={
                    eventInfo.navigationSet ? eventInfo.navigationSet.names : {}
                  }
                  error={error}
                  lobbySet={eventInfo.lobbySet || {}}
                  eventInfoCompleted={eventInfoCompleted}
                  setSelectedSession={setSelectedSession}
                  setStamp={setStamp}
                  selectedPT={selectedPT || {}}
                  showFuncCollection={showFuncCollection}
                />
              );
            }
            if (lobbyStyle === "LOBBY_2D_04") {
              return (
                <Lobby2DStyle4
                  isLang={eventInfo.isLang}
                  loginType={eventInfo.loginType}
                  names={
                    eventInfo.navigationSet ? eventInfo.navigationSet.names : {}
                  }
                  error={error}
                  lobbySet={eventInfo.lobbySet || {}}
                  eventInfoCompleted={eventInfoCompleted}
                  setSelectedSession={setSelectedSession}
                  setStamp={setStamp}
                  selectedPT={selectedPT || {}}
                  showFuncCollection={showFuncCollection}
                  replayList={replayList}
                  theaterCategoryList={theaterCategoryList}
                />
              );
            }
          }}
        ></Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
}

export default App;
