import { createContext, useState, useContext, useEffect, useRef } from "react";
import useEventInfo from "../hooks/useEventInfo";

const EventContext = createContext();

const EventContextProvider = ({ children }) => {
  const variables = { idx: 36 };
  const { eventInfo, error, refetchEventInfo } = useEventInfo(variables);
  const value = {
    eventInfo,
    refetchEventInfo,
    error,
  };
  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

export { EventContext, EventContextProvider };
