import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import util from "../../../../util/index";
import { gql, useMutation } from "@apollo/client";
import { ASSETS_HOST } from "../../../../config/define";

export const FORMAT_USER_PASSWORD = gql`
  mutation formatUserPassword($input: userFormatPassword) {
    formatUserPassword(input: $input) {
      email
      event_idx
    }
  }
`;

const FindPW = ({ goToPrev, openAlert }) => {
  const [formatUserPassword] = useMutation(FORMAT_USER_PASSWORD, {
    onError: (error) => {
      console.log("FORMAT_USER_PASSWORD error ::", error);
      openAlert({ bool: true, msg: error.message });
    },
    onCompleted: (data) => {
      console.log("FORMAT_USER_PASSWORD data ::", data);
      goToPrev();
      openAlert({
        bool: true,
        msg: `Your password has been changed successfully.
Please log in with your new password.
비밀번호가 변경되었습니다.
새로운 비밀번호로 다시 로그인해주세요.`,
        type: "success",
      });
    },
  });
  const router = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const confirm = () => {
    goToPrev();
    setConfirmAlert(false);
  };

  const [message, setMessage] = useState({ code: null, error: "" });

  const [form, setForm] = useState({
    email: "",
  });

  const onChange = (e) => {
    const { id, value, name } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (isLoading) return null;
    setIsLoading(true);

    if (util.isEmpty(form.email)) {
      setMessage({
        code: 1000,
        error: `Please check the email.
이메일을 정확히 입력해 주세요.`,
      });
      setIsLoading(false);
      return;
    }

    // const eventIdx = util.getCookieValue("selectedEventIdx");

    const pwFindForm = {
      email: form.email,
      // event_idx: eventIdx,
    };

    try {
      //   const { data } = await apiClient.post("/auth/password", pwFindForm);
      //   if (data) {
      //     setConfirmAlert(true);
      //     setIsLoading(false);
      //   }
      // goToPrev();
      formatUserPassword({ variables: { input: form } });
    } catch (err) {
      console.log("err", err);
      //   const { error, code } = err;
      //   setMessage({ code, error });
      //   setIsLoading(false);
    }
    return false;
  };

  return (
    <div className="modal popup">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small zoomIn">
        <div className="modal__header">
          <button className="back" onClick={goToPrev}>
            <ion-icon name="arrow-back"></ion-icon>
          </button>
          <section className="modal__tit">
            <img
              className="alert"
              src={`${ASSETS_HOST}/img/icon_alert.png`}
              alt="alert"
            />
            <h2>비밀번호 찾기</h2>
            <p>
              등록하신 E-mail을 입력해주세요.
              <br />
              등록하신 E-mail로 임시 비밀번호를 발송합니다.
            </p>
          </section>
        </div>
        <div className="modal__content lgmh">
          <div className="loginbox">
            <form className="loginbox__form" action="#" name="reset">
              <div className="text__box">
                <label htmlFor="email">
                  <input
                    name="email"
                    type="email"
                    required
                    placeholder="이메일(아이디)를 입력해 주세요."
                    onChange={onChange}
                  />
                </label>
              </div>
              <input
                type="submit"
                name="submit"
                className="submit find-pw__submit-btn"
                value="제출"
                onClick={submit}
              ></input>
              <button
                onClick={goToPrev}
                type="cancel-btn"
                className="find-pw__cancel-btn"
              >
                취소
              </button>
              <p className="error-msg hidden">
                Please Check Your E-mail Address.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPW;
