import React from "react";

const CustomLoadingScreen = React.memo(
  () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <div className="loader__spinner"></div>
        </div>
      </div>
    );
  },
  (_, _2) => true
);

export default CustomLoadingScreen;
