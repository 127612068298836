import React from "react";
import { gql, useMutation } from "@apollo/client";
import { onStamp } from "../query/queries";

const ON_STAMP = gql`
  ${onStamp}
`;

const useStamp = (eventType) => {
  const [_onStamp, { loading, data, error }] = useMutation(ON_STAMP);

  const setStamp = (type) => {
    if (eventType && eventType !== "unlogin") {
      _onStamp({ variables: { type } });
    }
  };

  return {
    setStamp,
    stampError: error,
  };
};

export default useStamp;
