import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import utils from "../../../util/index";

const TRANSLATE_NAME = gql`
  query TRANSLATE_NAME($name: String) {
    translate(name: $name) {
      name
    }
  }
`;

const NameTranslation = ({ name, openAlert, prev }) => {
  const [translateByPapago, { error, loading, data }] = useLazyQuery(
    TRANSLATE_NAME,
    {
      onError: (err) => {
        console.error("TRANSLATE_NAME ERROR ::", err);
      },
    }
  );

  const onNameChange = (e) => (name.en = e.target.value);

  const translate = () => {
    if (!name.en) return;
    if (name.en.length > 30) {
      openAlert({
        bool: true,
        msg: "English name is not allowed to be more than 30 charaters long",
      });
      return;
    }
    translateByPapago({ variables: { name: name.en } });
  };

  const onEnglishnameChange = (e) => {
    setEnglishName(e.target.value);
  };

  useEffect(() => {
    if (!loading && data) {
      console.log(data);
      const { translate } = data;
      name.kr = translate.name;
      prev(2);
    }
  }, [loading, data]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") translate();
  };

  return (
    <div className="modal__content hd-dsc-l mbs">
      <div className="content-box">
        <div className="plus-seoul">
          <div className="membership step4">
            <h3 className="mb-desc">
              Please enter a name and press the Convert button.
            </h3>
            <div className="mb-input">
              <input
                type="text"
                onKeyPress={onKeyPress}
                onChange={onNameChange}
              />
            </div>
            <div className="take-btn">
              <button className="on" onClick={translate}>
                Convert
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameTranslation;
