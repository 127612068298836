import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";
import * as GUI from "@babylonjs/gui";
import { pipe } from "./fn";
import { PBRBaseMaterial } from "@babylonjs/core";
import { ASSETS_HOST } from "../config/define";

const Mesh = {
  import: async (scene, url, progress = () => {}, complete = () => {}) => {
    BABYLON.SceneLoader.ImportMesh(
      "",
      url,
      "",
      scene,
      (newMeshes) => {
        complete(newMeshes);
      },
      progress,
      (error) => {
        console.error(error);
        // if (meshName === undefined || meshName === null) {
        //   console.error("3D Model URL is null !");
        // } else {
        console.error("3D Model URL isn't null, but it load failed !");
        // }
      }
    );
  },
  importAsync: (scene, url, progress = () => {}) => {
    return BABYLON.SceneLoader.ImportMeshAsync("", url, "", scene, progress);
  },
  createTextBlock: (text, options = { color: "white" }) => {
    const textBlock = new GUI.TextBlock();
    textBlock.text = text || "DefaulTextBlock";
    textBlock.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    textBlock.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    // options
    Object.keys(options).forEach((key) =>
      options[key] ? (textBlock[key] = options[key]) : null
    );
    return textBlock;
  },
  // 타이틀, 설명, 입장
  createButton: (
    name,
    text,
    url,
    options = {
      isEnabled: true,
      alpha: 1,
      width,
      height,
    }
  ) => {
    const button = new GUI.Button.CreateSimpleButton(name, text);
    // default
    button.isEnabled = false;
    // button.image.width = "100%";
    // button.image.height = "100%";
    button.thickness = 0;
    button.color = "white";
    button.background = "rgba(0,0,0,0.3)";
    button.cornerRadius = 15;
    // button.fontSizeInPixels = button.fontSize;
    // button.adaptWidthToChildren = true;
    // button.adaptHeightToChildren = true;
    // button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    // button.fontSizeInPixels(
    //   ((window.innerHeight + window.innerWidth) / 2) * 0.2
    // );
    // ((window.innerHeight + window.innerWidth) / 2) * 0.2;
    // button.image.stretch = GUI.Image.STRETCH_FILL;
    // options
    Object.keys(options).forEach((key) => {
      const option = options[key];
      if (typeof option !== "object") {
        button[key] = options[key];
      } else {
        const keys = Object.keys(options[key]);
        keys.forEach((k) => {
          button[key][k] = options[key][k];
        });
      }
    });

    if (options.onClick) {
      button.onPointerClickObservable.add((e) => {
        options.onClick();
      });
    }

    return button;
  },
  createImageButton: (
    advancedTexture,
    target,
    linkOffsetOption = {
      linkOffsetX: 0,
      linkOffsetY: 55,
    },
    click,
    iconPath = `${ASSETS_HOST}/img/icon_landmark-enter.png`
  ) => {
    var enterBtn = new GUI.Button.CreateImageButton(
      "EnterButton",
      "",
      iconPath
    );
    advancedTexture.addControl(enterBtn);
    enterBtn.image.width = 1;
    enterBtn.image.height = 1;
    if (window.orientation == null) {
      enterBtn.height = "6%";
      enterBtn.width = "6%";
    } else {
      if (window.orientation == 90 || window.orientation == -90) {
        //Landscape Mode
        enterBtn.height = 0.15;
        enterBtn.width = 0.15;
      } else {
        //Portrait Mode
        enterBtn.height = 0.2;
        enterBtn.width = 0.2;
      }
    }
    enterBtn.alpha = 0.95;
    enterBtn.cornerRadius = 100;
    enterBtn.thickness = 0;
    enterBtn.linkWithMesh(target);
    Object.keys(linkOffsetOption).forEach((k) => {
      enterBtn[k] = linkOffsetOption[k];
    });
    enterBtn.hoverCursor = "pointer";
    enterBtn.onPointerClickObservable.add(() => {
      click();
    });
    return enterBtn;
  },
  createDiscButton: (name, col3, scene) => {
    const disc = BABYLON.MeshBuilder.CreateDisc(name, { radius: 0.6 });
    const mat = new BABYLON.StandardMaterial("disc2-mat", scene);
    mat.emissiveColor = col3;
    disc.material = mat;
    mat.zOffset = 1;
    return disc;
  },
  createSVGButton: (name, svgUrl, col3, scene) => {
    const disc = BABYLON.MeshBuilder.CreateDisc(name, { radius: 0.3 });
    const mat = new BABYLON.StandardMaterial("svg_material", scene);
    mat.opacityTexture = new BABYLON.Texture(svgUrl, scene, false, false);
    mat.opacityTexture.hasAlpha = true;
    mat.emissiveColor = col3;
    disc.material = mat;
    return disc;
  },
  createBox: (name = "box", option, scene) => {
    return option.size
      ? new BABYLON.Mesh.CreateBox(name, option.size, scene)
      : new BABYLON.BoxBuilder.CreateBox(name, option, scene);
  },
  createPlane: (name, options) => {
    return BABYLON.MeshBuilder.CreatePlane(name || "plane", options || {});
  },
  delete: (clickedMesh) => {
    clickedMesh.dispose();
    clickedMesh = null;
  },
  setPosition: (mesh, pos) => {
    Object.keys(pos).forEach((v) => {
      mesh.position[v] = pos[v];
    });
  },
  setRotation: (mesh, vec3) => {
    mesh.rotation = vec3;
  },
  makeMaterialToVideo: (scene, videoUrl, target = BABYLON.Mesh) => {
    if (!target.material) {
      target.material = new BABYLON.StandardMaterial(
        `${target.id}_standMaterial`,
        scene
      );
    }
    var videoTexture = new BABYLON.VideoTexture(
      `${target.id}_VideoTexture`,
      videoUrl,
      scene,
      true,
      true,
      BABYLON.Texture.TRILINEAR_SAMPLINGMOD,
      {
        muted: true,
        autoPlay: true,
        autoUpdateTexture: true,
        loop: true,
      }
    );

    target.material.emissiveTexture = videoTexture;
    target.material.freeze();

    return videoTexture;
  },
  // todo 분리
  createTitleAndDescGrid: (
    title,
    { desc = "", notice = "" },
    thumbnail,
    options
  ) => {
    const grid = new GUI.Grid();

    Object.keys(options).forEach((key) => {
      grid[key] = options[key];
    });

    grid.addColumnDefinition(6, true);
    grid.addColumnDefinition(294, true);
    grid.addColumnDefinition(6, true);
    grid.addRowDefinition(6, true);
    grid.addRowDefinition(0.4, false);
    grid.addRowDefinition(0.1, false);
    grid.addRowDefinition(0.3, false);
    grid.addRowDefinition(0.1, false);
    grid.addRowDefinition(6, true);
    grid.addControl(
      Mesh.createButton("desc", "", thumbnail, {
        width: "294px",
        height: "100%",
        cornerRadius: 0,
        paddingRight: "6px",
      }),
      1,
      1
    );

    grid.addControl(
      Mesh.createTextBlock(title, {
        fontSize: "18px",
        color: "black",
        textHorizontalAlignment: 3,
      }),
      2,
      1
    );
    const descGrid = new GUI.Grid();
    descGrid.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    descGrid.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    descGrid.width = "100%";
    const descText = Mesh.createTextBlock(desc, {
      fontSize: "13px",
      color: "black",
      textWrapping: true,
      textHorizontalAlignment: GUI.Control.HORIZONTAL_ALIGNMENT_LEFT,
      paddingLeft: "0px",
    });
    descText.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    descText.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    descText.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    descText.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    descGrid.addControl(descText);

    if (notice) {
      const enterNoticeGrid = new GUI.Grid();
      enterNoticeGrid.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
      enterNoticeGrid.horizontalAlignment =
        GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
      enterNoticeGrid.width = "100%";

      const enterNoticeText = Mesh.createTextBlock(notice, {
        fontSize: "13px",
        color: "red",
        textWrapping: true,
        textHorizontalAlignment: GUI.Control.HORIZONTAL_ALIGNMENT_LEFT,
        paddingTop: "13px",
      });
      enterNoticeText.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
      enterNoticeText.horizontalAlignment =
        GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
      enterNoticeText.textVerticalAlignment =
        GUI.Control.VERTICAL_ALIGNMENT_TOP;
      enterNoticeText.textHorizontalAlignment =
        GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
      enterNoticeGrid.addControl(enterNoticeText);
      grid.addControl(enterNoticeGrid, 4, 1);
    }

    descGrid.onWheelObservable.add((data) => {
      const descTextTotalHeight =
        (descText.fontSizeInPixels + 3.5) * descText.lines.length;
      const currentTop = Math.abs(+descText.top.slice(0, -2));

      if (
        data.y === 100 &&
        descTextTotalHeight > currentTop + descGrid.heightInPixels
      ) {
        // scroll up
        descText.top = `${
          +descText.top.slice(0, -2) - descText.fontSizeInPixels
        }px`;
      } else if (data.y === -100 && +descText.top.slice(0, -2) < 0) {
        // scroll down
        const result = +descText.top.slice(0, -2) + descText.fontSizeInPixels;
        descText.top = `${result}px`;
      }
      descText.height = descTextTotalHeight + "px";
    });

    grid.addControl(descGrid, 3, 1);

    return grid;
  },
};

const Material = {
  create: (name = "standard material", type, scene, options) => {
    // todo type 분기
    const material = new BABYLON.StandardMaterial(name, scene);
    Object.keys(options).forEach((key) => {
      material[key] = options[key];
    });
    return material;
  },
  setTexture: (
    material,
    { type = "diffuse", url = "", scene },
    options = {}
  ) => {
    if (!material) return "";
    const textureType = `${type}Texture`;
    console.log("scene ", scene);
    material[textureType] = new BABYLON.Texture(url, scene);
    const hasOptions = Object.keys(options).length;
    if (hasOptions) {
      Object.keys(options).forEach((key) => {
        material[textureType][key] = options[key];
      });
    }
  },
};

const Camera = {
  set: (type, options, props = {}) => {
    const cameras = {
      arc: (options) => {
        const { name, alpha, beta, radius, target, scene } = options;
        return new BABYLON.ArcRotateCamera(
          name,
          alpha,
          beta,
          radius,
          target,
          scene
        );
      },
      universal: (options) => {
        const { name, alpha, beta, radius, target, scene } = options;
        return new BABYLON.UniversalCamera(
          name,
          alpha,
          beta,
          radius,
          target,
          scene
        );
      },
      free: (options) => {
        const { name, position, scene } = options;
        return new BABYLON.FreeCamera(name, position, scene);
      },
    };

    const camera = cameras[type](options);

    const keys = Object.keys(props);

    if (keys)
      keys.forEach((p) => {
        camera[p] = props[p];
      });

    return camera;
  },
  arc: {
    setBetaLimit: (camera, limit) => {
      camera.lowerBetaLimit = limit.lower;
      camera.upperBetaLimit = limit.upper;
    },
    setWheelPrecision: (camera, precision) =>
      (camera.wheelPrecision = precision),
    setAngularSensibility: (camera, options) => {
      camera.angularSensibilityX = options.x;
      camera.angularSensibilityY = options.y;
    },
  },
  moveTo: (camera, target, alpha, beta, radius, endCallback = () => {}) => {
    camera.lowerRadiusLimit = 0;
    const Ease = new BABYLON.PowerEase();
    Ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);
    BABYLON.Animation.CreateAndStartAnimation(
      "targetMove",
      camera,
      "target",
      50,
      80,
      camera.target,
      target,
      0,
      Ease
    );

    BABYLON.Animation.CreateAndStartAnimation(
      "alphaMove",
      camera,
      "alpha",
      50,
      80,
      camera.alpha,
      alpha,
      0,
      Ease
    );

    BABYLON.Animation.CreateAndStartAnimation(
      "betaMove",
      camera,
      "beta",
      50,
      80,
      camera.beta,
      beta,
      0,
      Ease
    );

    BABYLON.Animation.CreateAndStartAnimation(
      "cameraMove",
      camera,
      "radius",
      50,
      80,
      camera.radius,
      radius,
      0,
      Ease,
      () => {
        camera.lowerRadiusLimit = radius;
        if (endCallback) endCallback();
      }
    );
  },
};

const Light = {
  setHemisphericLight: (scene, direction) => {
    var light = new BABYLON.HemisphericLight("hemiLight", direction, scene);
    light.diffuse = new BABYLON.Color3(255, 255, 255);
    return light;
  },
  setLightAttribute: (light, options) => {
    Object.keys(options).forEach((key) => {
      light[options] = key;
    });
  },
};

const Calc = {
  degreeToRadian: (degree) => {
    return (degree * Math.PI) / 180;
  },
  radianToDegree: (radian) => {
    return (radian * 180) / Math.PI;
  },
};

const Animation = {
  createAndStart: (options) => {
    const { name = "", node, property, from, to } = options;
    if (!node) throw Error("node shouldn't be null");
    const Ease = new BABYLON.PowerEase();
    Ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

    BABYLON.Animation.CreateAndStartAnimation(
      name,
      node,
      property,
      30,
      60,
      from,
      to,
      0,
      Ease
    );
  },
  create: ({ name, property, keyframes }) => {
    const animation = new BABYLON.Animation(
      name,
      property,
      60,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    animation.setKeys(keyframes);
    return animation;
  },

  setAnimation: (target, animation) => {
    if (!target.animations) {
      target.animations = [];
    }

    target.animations.push(animation);
  },
  intro: (camera, ref) => {
    const Ease = new BABYLON.PowerEase();
    Ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);
    return new Promise((resolve, reject) => {
      const timeOutId = setTimeout(() => {
        BABYLON.Animation.CreateAndStartAnimation(
          "alphaMove",
          camera,
          "alpha",
          60,
          180,
          camera.alpha,
          1.5588,
          0,
          Ease,
          () => {}
        );
        BABYLON.Animation.CreateAndStartAnimation(
          "betaMove",
          camera,
          "beta",
          60,
          180,
          camera.beta,
          1.061,
          0,
          Ease,
          () => {
            ref.current = false;
            resolve(true);
          }
        );
        BABYLON.Animation.CreateAndStartAnimation(
          "radiusMinus",
          camera,
          "radius",
          60,
          120,
          camera.radius,
          1250,
          0,
          Ease,
          () => {
            window.clearInterval(timeOutId);
          }
        );
      }, 500);
    });
  },
};

const Event = {
  setActionManager: (scene) => {
    return new BABYLON.ActionManager(scene);
  },
  setExecuteCodeAction: (eventType, cb) => {
    return new BABYLON.ExecuteCodeAction(eventType, cb);
  },
  registerAction: (mesh, action) => {
    if (mesh.actionManager.actions.length) {
      mesh.actionManager.actions.push(action);
      return;
    }
    mesh.actionManager.registerAction(action);
  },
  onPrePointerObservablbe: (scene, type, cb) => {
    scene.onPrePointerObservable.add(
      (pointerInfo) => {
        const { event } = pointerInfo;
        cb(event);
      },
      type,
      false
    );
  },
  onPickTriggerHandler: (scene, mesh, cb) => {
    if (!mesh.actionManager) {
      mesh.isPickable = true;
      mesh.actionManager = Event.setActionManager(scene);
    }
    mesh.actionManager.registerAction(
      Event.setExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, cb)
    );
  },
  onPointerOverHandler: (scene, mesh, cb) => {
    if (!mesh.actionManager) {
      mesh.isPickable = true;
      mesh.actionManager = Event.setActionManager(scene);
    }
    mesh.actionManager.registerAction(
      Event.setExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, cb)
    );
  },
  onPointerOutHandler: (scene, mesh, cb) => {
    if (!mesh.actionManager) {
      mesh.isPickable = true;
      mesh.actionManager = Event.setActionManager(scene);
    }
    mesh.actionManager.registerAction(
      Event.setExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, cb)
    );
  },
  bind: (mesh, obj, scene) => {
    const { type, cb } = obj;
    if (!mesh.actionManager) {
      const actionManager = Event.setActionManager(scene);
      mesh.actionManager = actionManager;
    }
    const action = Event.setExecuteCodeAction(BABYLON.ActionManager[type], cb);
    Event.registerAction(mesh, action);
  },
};

export default {
  Mesh,
  Material,
  Camera,
  Light,
  Calc,
  Animation,
  Event,
};
