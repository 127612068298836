import "moment-timezone";
import React, { useState } from "react";
import { useHistory } from "react-router";
import SpeakerDetail from "../speaker/SpeakerDetail";
import { ModalPortalContext } from "../../../../../context/ModalContext";
import LiveStreaming from "../video/LiveStreaming";
import Survey from "../../../../survey/Survey.jsx";
import Session from "./ProgramInfoSession";

const formatter = {
  workshop: {
    kr: "씨어터",
    en: "Theater Hall",
  },
  conference: {
    kr: "컨퍼런스홀",
    en: "Conference Hall",
  },
  open_stage: {
    kr: "콘서트홀",
    en: "Concert Hall",
  },
};

const ProgramInfo = ({ scheduleList = [], close, user, pt_survey_active }) => {
  const history = useHistory();
  const [profile, setProfile] = useState({});

  const goTo = (place) => {
    const url = `/${place.split("_").join("")}`;
    history.push(url);
    close();
  };

  const openDocument = (e) => {
    const { url } = e.currentTarget.dataset;
    if (url) window.open(url, "_blank");
  };

  const showProfile = (speaker) => setProfile(speaker);
  const closeProfile = () => setProfile({});

  const { triggerModal } = React.useContext(ModalPortalContext);

  const handleOpenVod = (p) => {
    const openProgramInfo = () => {
      triggerModal({
        bool: true,
        props: {
          scheduleList,
          user,
          pt_survey_active,
        },
        content: {
          title: "PROGRAM INFO",
          Component: (props) => {
            return <ProgramInfo {...props} />;
          },
          hasOwnContainer: true,
        },
        hasPrevButton: false,
      });
    };
    const openSurvey = () => {
      triggerModal({
        bool: true,
        props: {          
          url: "https://docs.google.com/forms/d/e/1FAIpQLSfXmoTJlTXd11l3jvWh2rWIshzgscFLoZoVWVYEqRzU5ylM4g/viewform",
          onClosed: openProgramInfo,
        },
        content: {
          title: "",
          Component: (props) => {
            return <Survey {...props} />;
          },
          hasOwnContainer: true,
        },
      });
    };

    triggerModal({
      bool: true,
      props: {
        presentation: p,
        user,
        // 231102 김현수 - 영상 시청 후 설문조사 무조건 뜨는 부분 주석
        onClosed: pt_survey_active === "Y" ? openSurvey : openProgramInfo,
      },
      content: {
        title: p.p_title,
        Component: (props) => <LiveStreaming {...props} />,
        hasOwnContainer: true,
      },
      hasPrevButton: true,
    });
  };

  return (
    <>
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__wrapper wide zoomIn">
          <div className="modal__header">
            <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button>
            <section className="modal__tit">
              <h2>Conference Schedule</h2>
            </section>
          </div>
          <div className="modal__content pd0">
            <div className="content-box">
              <div className="program-box">
                <section className="program-box__content">
                  <article
                    className="listing-programContent target"
                    // style={{ display: "block" }}
                  >
                    <ul>
                      {/* 탭 */}
                      <li className="programContent top">
                        <div className="programContent__cell">Time</div>
                        <div className="programContent__cell">Program</div>
                        <div className="programContent__cell">Play</div>
                        <div className="programContent__cell">Documents</div>
                      </li>
                      {/* row */}
                      {scheduleList[0]?.sessionList?.map((session, i) => {
                        return (
                          <Session
                            key={i}
                            goTo={goTo}
                            openDocument={openDocument}
                            showProfile={showProfile}
                            session={session}
                            handleOpenVod={handleOpenVod}
                            user={user}
                          />
                        );
                      })}
                    </ul>
                  </article>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(profile).length && (
        <SpeakerDetail profile={profile} close={closeProfile} />
      )}
    </>
  );
};

export default ProgramInfo;
