import React, { useState, useRef, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { consent } from "../../../../../query/queries";

export const REGISTER_ACCOUNT = gql`
  mutation createUser($input: userInput) {
    createUser(input: $input) {
      name
      email
      password
      mobile_no
      job_title
      organization
      privacy_yn
      user_yn
    }
  }
`;

export const CONSENT = gql`
  ${consent}
`;

const AccountRegistration = ({
  close,
  hasPrevButton,
  openAlert,
  goToPrev,
  children,
}) => {
  const [register, { data, loading }] = useMutation(REGISTER_ACCOUNT, {
    onError: (error) => {
      openAlert({ bool: true, msg: error.message });
    },
    onCompleted: (data) => {
      clearForm();
      goToPrev();
      openAlert({
        bool: true,
        msg: `Registration has been completed
등록이 완료되었습니다.
        `,
        type: "success",
      });
    },
  });
  const {
    loading: isConsentLoading,
    error,
    data: consentData,
  } = useQuery(CONSENT);
  const [type, setPolicyType] = useState("");
  const [agreements, setAgreement] = useState({
    user_yn: false,
    privacy_yn: false,
  });
  const formState = {
    name: useRef(),
    email: useRef(),
    password: useRef(),
    confirm_pw: useRef(),
    mobile_no: useRef(),
    job_title: useRef(),
    organization: useRef(),
  };
  const [validated, setValidated] = useState(false);

  const showAgreementPolicy = (e) => {
    e.preventDefault();
    const { dataset } = e.currentTarget;
    setPolicyType(dataset.type || "");
  };

  const closeAgreementPolicy = () => setPolicyType("");

  const onAgreementChanged = (e) => {
    if (!e.currentTarget) return null;
    const { id, checked } = e.currentTarget;
    setAgreement((prev) => {
      return {
        ...prev,
        [id]: checked,
      };
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    const {
      name,
      email,
      password,
      confirm_pw,
      mobile_no,
      job_title,
      organization,
    } = formState;
    const { user_yn, privacy_yn } = agreements;
    // const isInvalid =
    //   password !== confirm_pw ||
    //   !(name && email && password && confirm_pw && user_yn);
    const errorMessage = {
      email: `Please check the email.
이메일을 정확히 입력해주세요.`,
      name: `Please check the name.
이름을 입력해주세요.`,
      password: `Please check the password.
비밀번호를 입력해주세요.`,
      confirm: `Password confirmation doesn't match.
재확인 비밀번호가 일치하지 않습니다.`,
      mobile_no: `Please check the mobile.
      모바일 번호를 정확히 입력해주세요.`,
      user_yn: `The agreement to the User Terms and Privacy Policy is required.
이용약관 및 개인정보 수집 및 이용에 동의해주세요.`,
      job_title: `Please write your Job Title in 20 letters.
직위는 20자 내로 입력해주세요.`,
    };
    const conditions = [
      { email: email.current.value },
      { name: name.current.value },
      { password: password.current.value },
      { confirm: password.current.value === confirm_pw.current.value },
      { mobile_no: mobile_no.current.value },
      { job_title: job_title.current.value.length < 20 },
      { user_yn },
    ];
    const isValid = conditions.every((c) => {
      const arr = Object.entries(c);
      const [k, v] = arr[0];
      if (!v) {
        setValidated(true);
        openAlert({ bool: true, msg: errorMessage[k] });
        return false;
      }
      return true;
    });

    if (!isValid) return;

    try {
      register({
        variables: {
          input: {
            event_idx: 36,
            name: name.current.value,
            email: email.current.value,
            password: password.current.value,
            mobile_no: mobile_no.current.value,
            job_title: job_title.current.value,
            organization: organization.current.value,
            privacy_yn: privacy_yn ? "Y" : "N",
            user_yn: user_yn ? "Y" : "N",
          },
        },
      });
    } catch (err) {
      console.log("error", err);
    }
    return false;
  };

  const clearForm = () => {
    Object.keys(formState).forEach((k) => {
      formState[k].current.value = "";
    });
    setAgreement({
      user_yn: false,
      privacy_yn: false,
    });
  };

  const onInputChanged = (e) => {
    if (validated) setValidated(false);
    const { name, value } = e.target;
    // console.dir(formState[name]);
    formState[name].current.value = value;
  };

  useEffect(() => {
    if (error) {
      console.log("CONSENT ERROR ::", error);
    }
  }, [error]);

  return (
    <>
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__wrapper zoomIn">
          <div className="modal__header">
            {/* <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button> */}
            {hasPrevButton && (
              <button className="back" onClick={goToPrev}>
                <ion-icon name="arrow-back"></ion-icon>
              </button>
            )}
            <section className="modal__tit">
              {/* <h2>회원가입</h2> */}
              <h2>사전 등록하기</h2>
              <p>
                입력하신 이메일과 비밀번호로 컨퍼런스에 참가하실 수 있습니다.
              </p>
            </section>
          </div>
          <div className="modal__content hd-dsc">
            <div className="content-box">
              <form
                className={
                  validated
                    ? "registration-form was-validated"
                    : "registration-form"
                }
              >
                <div className="text__box">
                  <label htmlFor="name">
                    <p className="required">성명</p>
                    <input
                      ref={formState.name}
                      type="type"
                      name="name"
                      required
                      onChange={onInputChanged}
                    />
                  </label>
                </div>
                <div className="text__box">
                  <label htmlFor="email">
                    <p className="required">이메일(아이디)</p>
                    <input
                      ref={formState.email}
                      type="email"
                      name="email"
                      required
                      onChange={onInputChanged}
                    />
                  </label>
                </div>
                <div className="text__box">
                  <label htmlFor="password">
                    <p className="required">비밀번호</p>
                    <input
                      ref={formState.password}
                      type="password"
                      name="password"
                      required
                      onChange={onInputChanged}
                    />
                  </label>
                </div>
                <div className="text__box">
                  <label htmlFor="confirm_pw">
                    <p className="required">비밀번호(확인)</p>
                    <input
                      ref={formState.confirm_pw}
                      type="password"
                      name="confirm_pw"
                      required
                      onChange={onInputChanged}
                    />
                  </label>
                </div>
                <div className="text__box">
                  <label htmlFor="mobile_no">
                    <div style={{marginBottom: '0.5rem'}}>
                      <span className="required" style={{ marginRight: '1rem'}}>휴대폰 번호</span>
                      <span >(ex. 010-1234-5678)</span>                      
                    </div>
                    <input
                      style={{marginTop: ''}}
                      ref={formState.mobile_no}
                      type="text"
                      name="mobile_no"
                      required
                      onChange={onInputChanged}
                    />
                  </label>
                </div>

                <div className="text__box">
                  <label htmlFor="organization">
                    <p>회사명</p>
                    <input
                      ref={formState.organization}
                      type="organization"
                      name="organization"
                      onChange={onInputChanged}
                    />
                  </label>
                </div>
                <div className="text__box">
                  <label htmlFor="job_title">
                    <p>직위/직책</p>
                    <input
                      ref={formState.job_title}
                      type="job_title"
                      name="job_title"
                      onChange={onInputChanged}
                    />
                  </label>
                </div>

                <div className="policy-wrap">
                  <label htmlFor="user_yn" className="policy">
                    <input
                      type="checkbox"
                      name="user_yn"
                      id="user_yn"
                      required
                      checked={agreements.user_yn}
                      onChange={onAgreementChanged}
                    />
                    <b>&#91;필수&#93;</b>
                    <span>
                      <a
                        className="terms"
                        data-type="user"
                        onClick={showAgreementPolicy}
                      >
                        &ensp;필수동의 항목에 대한 개인정보 수집·이용 동의
                      </a>
                    </span>
                  </label>
                  <label htmlFor="privacy_yn" className="policy">
                    <input
                      type="checkbox"
                      name="privacy_yn"
                      id="privacy_yn"
                      checked={agreements.privacy_yn}
                      onChange={onAgreementChanged}
                    />
                    <b>&#91;선택&#93;</b>
                    <span>
                      <a
                        className="privacy"
                        data-type="privacy"
                        onClick={showAgreementPolicy}
                      >
                        &ensp;선택동의 항목에 대한 개인정보 수집·이용 동의
                      </a>
                    </span>
                  </label>
                </div>
                <div className="registration-form__button">
                  <button
                    type="button"
                    className="clearform"
                    onClick={clearForm}
                  >
                    양식 지우기
                  </button>
                  <button type="button" className="register" onClick={submit}>
                    등록하기
                  </button>
                </div>
                <p className="error-msg hidden">
                  Please Check Your Account Or Password.
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      {React.cloneElement(children, {
        close: closeAgreementPolicy,
        setAgreement,
        type,
        consent: {
          privacy: consentData ? consentData.event.privacy_consent : "",
          user: consentData ? consentData.event.user_consent : "",
        },
      })}
    </>
  );
};

export default AccountRegistration;
