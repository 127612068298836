import React from "react";
import { useApollo } from "../util/apolloClient";
import { ApolloProvider } from "@apollo/client";
import { ModalPortalProvider } from "../context/ModalContext";
import { UserLoginContextProvider } from "../context/UserLoginContext";
import ModalContainer from "./common/modal/ModalContainer";
import { SocketContextProvider } from "../context/SocketContext";
import { LoadingContextProvider } from "../context/LoadingContext";

const AppContainer = ({ children }) => {
  const client = useApollo();
  return (
    <ApolloProvider client={client}>
      {/* <BoothContextProvider> */}
      <ModalPortalProvider Container={ModalContainer}>
        <UserLoginContextProvider>
          <SocketContextProvider>
            <LoadingContextProvider>{children}</LoadingContextProvider>
          </SocketContextProvider>
        </UserLoginContextProvider>
      </ModalPortalProvider>
      {/* </BoothContextProvider> */}
    </ApolloProvider>
  );
};

export default AppContainer;
