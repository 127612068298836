function* _getCurrentValue(schedule) {
  for (let i = 0; i < schedule?.length; i++) {
    if (schedule[i].isToday) {
      yield { bool: true, value: schedule[i] };
    } else {
      yield { bool: false };
    }
  }
}

function* _getCurrentValueBy(schedule, idx) {
  for (let i = 0; i < schedule?.length; i++) {
    const result = schedule[i].idx === +idx;
    if (result) {
      return {
        bool: true,
        value: schedule[i],
      };
    }
    yield { bool: false };
  }
  yield { bool: false };
}

const loop = (iters) => {
  let result = false;
  for (let obj of iters) {
    const { bool, value } = obj;
    if (bool) {
      result = value;
      break;
    }
  }
  return result;
};

export const getTodaySchedule = (schedule) => loop(_getCurrentValue(schedule));

export const getSelectedSession = (sessions, idx) =>
  loop(_getCurrentValueBy(sessions, idx));
