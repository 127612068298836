import { Engine, Scene } from "@babylonjs/core";
import React, { useContext, useEffect, useRef } from "react";
import {
  CustomLoadingContext,
  CustomLoadingProvider,
} from "../../context/CustomLoadingContext";

const BaseScene = (props) => {
  const { customLoadingScreen } = useContext(CustomLoadingContext);
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    isSceneReady,
    width,
    height,
    reRender,
    onLazyLoading,
    canSkip,
    ...rest
  } = props;

  useEffect(() => {
    if (canvasRef.current) {
      const engine = new Engine(
        canvasRef.current,
        antialias,
        engineOptions,
        adaptToDeviceRatio
      );
      engine.loadingScreen = customLoadingScreen;
      engine.doNotHandleContextLost = true;
      engineRef.current = engine;
      const scene = new Scene(engine, sceneOptions);
      const resize = () => {
        // canvasRef.current.width = window.innerWidth;
        // canvasRef.current.height = window.innerHeight;
        if (window.devicePixelRatio === 1) {
          engine.setHardwareScalingLevel(1 / window.devicePixelRatio);
        } else {
          engine.setHardwareScalingLevel(0.8);
        }

        scene.getEngine().resize();
      };

      if (navigator.userAgent.indexOf("Mobile") > -1) {
        engine.setHardwareScalingLevel(0.5);
      }

      if (engine.webGLVersion === 1) {
        engine.setHardwareScalingLevel(1);
      } else if (window.MSInputMethodContext && document.documentMode) {
        engine.setHardwareScalingLevel(1);
      } else {
        if (window.devicePixelRatio === 1) {
          engine.setHardwareScalingLevel(1 / window.devicePixelRatio);
        } else {
          engine.setHardwareScalingLevel(0.8);
        }
        engine.resize();
      }

      if (scene.isReady()) {
        scene.cleanCachedTextureBuffer();
        props.onSceneReady(scene);
        // resize();
      } else {
        scene.onReadyObservable.addOnce((scene) => props.onSceneReady(scene));
      }

      engine.runRenderLoop(() => {
        if (typeof onRender === "function") {
          onRender(scene);
        }
        scene.render();
      });
      window.addEventListener("resize", resize);

      return () => {
        scene.getEngine().dispose();
        if (window) {
          window.removeEventListener("resize", resize);
        }
      };
    }
  }, [onSceneReady]);

  useEffect(() => {
    if (engineRef.current && onLazyLoading) {
      engineRef.current.displayLoadingUI();
    }
    if (engineRef.current && !onLazyLoading) {
      engineRef.current.hideLoadingUI();
    }
  }, [onLazyLoading]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        WebkitUserSelect: "none",
        WebkitTouchCallout: "none",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(56, 56, 56, 0.9)",
      }}
      {...rest}
    />
  );
};

const BaseSceneContainer = (props) => {
  return (
    <CustomLoadingProvider>
      <BaseScene {...props} />
    </CustomLoadingProvider>
  );
};

export default BaseSceneContainer;
