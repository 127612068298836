import React from "react";

const ExhibitionNavigator = ({ boothList, openExhibitorList, exhibitionHallNum }) => {

  return (
    <div className="exhibition-list">
      <section className="listing-zone">
        {boothList.map((b, i) => {
          return (
            <article
              key={b.name}
              className="listing-zone__item"
              data-id={b.custom_code}
              data-num={i}
              // data-id={i + 1}
              onClick={openExhibitorList}
            >
              <span className={`link ${i === Number(exhibitionHallNum) ? "on" : ""}`}>
                {b.name}
              </span>
            </article>
          );
        })}
      </section>
    </div>
  );
};

export default ExhibitionNavigator;
