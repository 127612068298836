import { useMemo } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import util from "./index.js";

let apolloClient;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

// const pcoHttpLink = createHttpLink({
//   uri: process.env.NEXT_PUBLIC_PCO_API_BASE_URL,
// });

// endpoints: { admin: process.env.NEXT_PUBLIC_ADMIN_API_BASE_URL , pco: process.env.NEXT_PUBLIC_PCO_API_BASE_URL }

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((err) => {
      const { message, locations, path } = err;
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (
        err.extensions.code === "UNAUTHENTICATED" &&
        typeof window !== "undefined"
      ) {
        util.deleteCookie("clientToken");
        location.href = "/";
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// const defaultOptions = {
//   watchQuery: {
//     fetchPolicy: "no-cache",
//     errorPolicy: "ignore",
//   },
//   query: {
//     fetchPolicy: "no-cache",
//     errorPolicy: "all",
//   },
// };

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined", // set to true for SSR
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache({
      // addTypename false면 manual merge func
      // addTypename: false,
      // typePolicies: {
      // }
    }),
  });
};

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === "undefined") return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
};

export const useApollo = (initialState) => {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
};

const authLink = setContext((_, { headers }) => {
  let tokenName = "token"; // for user

  if (window) {
    const pathname = window.location.pathname;
    // console.log("pathname ::", pathname);
    if (pathname.indexOf("/pco/") === 0) {
      tokenName = "pcoToken";
    } else if (pathname.indexOf("/admin/") === 0) {
      tokenName = "adminToken";
    }
  }
  // console.log("tokenName ::", tokenName);
  const token = tokenName ? util.getCookieValue("clientToken") : "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      //role: "pco"
    },
  };
});
