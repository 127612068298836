import React from "react";

const TheaterVodList = ({ title, categoryList = [], play, close }) => {
  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper video">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>{title}</h2>
          </section>
        </div>
        <div className="modal__content video-list">
          <div className="content-box">
            <div className="video-box">
              <ul>
                {categoryList.length
                  ? categoryList?.map((v) => {
                    console.log('the v ::', v)
                      // return v.category_name ? (
                      //   <li key={v.name}>
                      //     <div
                      //       className="video-box__item"
                      //       // onClick={() => play(title, v, categoryList)}
                      //       onClick={() => play(title, v, v.theaterVodList)}
                      //     >
                      //       <img src={v.cover_url} alt="Video Thumbnail" />
                      //       <p className="tit">{v.name}</p>
                      //     </div>
                      //   </li>
                      // ) : (
                      //   <li key={v.name}>
                      //     <div
                      //       className="video-box__item"
                      //       style={{ opacity: 0, pointerEvents: "none" }}
                      //     >
                      //       <img alt="Video Thumbnail" />
                      //     </div>
                      //   </li>
                      // );
                      return v.category_name !== '' && (
                          <li key={v.name}>
                          <div
                            className="video-box__item"
                            onClick={() => play(title, v, v.theaterVodList)}
                          >
                            <img src={v.cover_url} alt="Video Thumbnail" />
                            <p className="tit">{v.name}</p>
                          </div>
                        </li>
                      )
                    })
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheaterVodList;
