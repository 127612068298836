import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import util from "../../../../../util/index";
import {} from "react";

const COMMENT_BOX_TOKEN = gql`
  query getCommentBoxToken(
    $clientToken: String!
    $roomType: String!
    $roomCode: String!
    $roomTitle: String!
  ) {
    getGoventCommentBoxToken(
      clientToken: $clientToken
      roomType: $roomType
      roomCode: $roomCode
      roomTitle: $roomTitle
    ) {
      commentBoxToken
    }
  }
`;

const CommentBox = ({ roomCode, roomTitle }) => {
  const [getToken, { error, loading, data }] = useLazyQuery(COMMENT_BOX_TOKEN);
  const [goventioToken, setGoventioToken] = useState("");

  useEffect(() => {
    if (roomCode && roomTitle) {
      getToken({
        variables: {
          roomType: "QA",
          roomCode,
          roomTitle,
          clientToken: util.getCookieValue("clientToken"),
        },
      });
    }
  }, [roomCode, roomTitle]);

  useEffect(() => {
    if (!loading && data) {
      setGoventioToken(data.getGoventCommentBoxToken.commentBoxToken || "");
    }
    if (error) {
      console.error("query getGoventCommentBoxToken error ::", error);
    }
  }, [loading, data, error]);

  // const getGoventioToken = async (currentConference) => {
  //     try {
  //         if (currentConference.idx === 104) currentConference.idx = 106;
  //         // this is client's special request
  //         const res = await apiClient.post("/goventio/comment", {
  //             roomType: "QA",
  //             roomCode: currentConference.idx,
  //             roomTitle: currentConference.title_ko,
  //         });
  //         // console.log("res ::", res);
  //         const { data } = res;
  //         setGoventioToken(data.token);
  //     } catch (error) {
  //         console.error("post booth chat waiting form error:  ", error);
  //     }
  // };
  return (
    <div className="live-session__comment-box">
      {goventioToken ? (
        <iframe
          src={`https://comment.govent.io/?token=${goventioToken}`}
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      ) : null}
    </div>
  );
};

export default CommentBox;
