import { gql, useMutation } from "@apollo/client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CompanyInfo from "../../components/exhibition/CompanyInfo";
import ExhibitionNavigator from "../../components/exhibition/ExhibitionNavigator";
import ExhibitorList from "../../components/exhibition/type/ExhibitorList";
import { BoothContext, BoothContextProvider } from "../../context/BoothContext";
import { ModalPortalContext } from "../../context/ModalContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import useResize from "../../hooks/useResize";
import {
  indoorExhibition,
  addStatisticCompanyVisit,
} from "../../query/queries";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import define2D from "../../config/define2D";
import IndoorExhibition2DStyle1 from "./IndoorExhibition2DStyle1";
import IndoorExhibition2DStyle2 from "./IndoorExhibition2DStyle2";
import IndoorExhibition2DStyle3 from "./IndoorExhibition2DStyle3";

const ADD_STATISTIC_COMPANY_VISIT = gql`
  ${addStatisticCompanyVisit}
`;

const Booth01Pusher = styled.div`
  position: absolute;
  top: 178px;
  left: 150px;
  width: 430px;
  height: 300px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
`;
const Booth02Pusher = styled.div`
  position: absolute;
  top: 140px;
  left: 700px;
  width: 400px;
  height: 300px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
`;
const Booth03Pusher = styled.div`
  position: absolute;
  top: 100px;
  left: 1180px;
  width: 400px;
  height: 300px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
`;
const Booth04Pusher = styled.div`
  position: absolute;
  top: 590px;
  left: 60px;
  width: 550px;
  height: 400px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
`;
const Booth05Pusher = styled.div`
  position: absolute;
  top: 530px;
  left: 750px;
  width: 520px;
  height: 400px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
`;
const Booth06Pusher = styled.div`
  position: absolute;
  top: 470px;
  left: 1360px;
  width: 500px;
  height: 370px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
`;

const IndoorExhibition = ({
  eventInfoCompleted,
  indoorExhibitionSet,
  loginType,
  error: indoorExhibitionSetError,
}) => {
  const [addStatisticCompanyVisit] = useMutation(ADD_STATISTIC_COMPANY_VISIT);
  const contentWrapRef = useRef(null);
  const { user } = useContext(UserLoginContext);
  const { boothList, categoryList, companyInfo, refetch } =
    useContext(BoothContext);
  const { triggerModal } = useContext(ModalPortalContext);
  const sceneRef = useRef(null);
  const boothListRef = useRef(boothList);
  const { contentWrapStyle, contentResizeStyle } = useResize(contentWrapRef);
  const [onLazyLoading, setOnLazyLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [exhibitionHallStyle, setExhibitionHallStyle] = useState();
  const prevExhibitionHallNum = localStorage.getItem("prevExhibitionHallNum");
  const [exhibitionHallNum, setExhibitionHallNum] = useState(
    prevExhibitionHallNum ? prevExhibitionHallNum : "0"
  );
  // console.log("prevExhibitionHallNum", prevExhibitionHallNum);
  const history = useHistory();
  // console.log("history", history);

  const openExhibitorList =
    // useCallback(
    (e) => {
      e.preventDefault();
      const { id: custom_code, num: _exhibitionHallNum } =
        e.currentTarget.dataset;
      // console.log("custom_code>>", custom_code);
      setExhibitionHallStyle(custom_code);
      // console.log("_exhibitionHallNum", _exhibitionHallNum);
      setExhibitionHallNum(_exhibitionHallNum);
      localStorage.setItem("prevExhibitionHallNum", _exhibitionHallNum);
    };
  //   ,[boothList]
  // );

  useEffect(() => {
    if (boothList.length && exhibitionHallNum) {
      console.log("boothList", boothList);
      // console.log("boothList[0]?.custom_code", boothList[0]?.custom_code);
      setExhibitionHallStyle(boothList[exhibitionHallNum].custom_code);
    }
  }, [boothList, exhibitionHallNum]);

  const boothPusher = (e) => {
    const { id: companyIdx } = e.currentTarget.dataset;
    if (!companyIdx) return;

    addStatisticCompanyVisit({ variables: { company_idx: +companyIdx } });
    history.push(`/exhibition/booth/${companyIdx}`);
  };

  useEffect(() => {
    if (indoorExhibitionSetError) {
      setOnLazyLoading(false);
      setIsLoading(false);
      setStamp("exhibition_booth_visit");
    }
  }, [indoorExhibitionSetError]);

  useEffect(() => {
    return () => {
      if(!window.location.href.split('/')[5]) {
        localStorage.setItem("prevExhibitionHallNum", "0")
      }
    }
  }, [])

  // useEffect(() => {
  //   console.log("exhibitionHallStyle", exhibitionHallStyle);
  // }, [exhibitionHallStyle]);

  return (
    <>
      <div
        style={{
          height: "100%",
          background:
            "linear-gradient(rgb(4, 16, 21) 0%, rgb(12, 41, 54) 100%)",
        }}
      >
        <div
          className="lobby wrapper login-wrap"
          style={{
            height: "100%",
            position: "relative",
            background: "none",
          }}
        >
          {isLoading && boothList && boothList.length && (
            <ExhibitionNavigator
              boothList={boothList}
              openExhibitorList={openExhibitorList}
              exhibitionHallNum={exhibitionHallNum}
            />
          )}
          <div className="content-wrap" style={contentWrapStyle}>
            <div
              className="canvas-wrap"
              ref={contentWrapRef}
              style={contentResizeStyle}
            >
              {exhibitionHallStyle === "BOOTH_EXHIBITION_2D_01" && (
                <IndoorExhibition2DStyle1
                  boothList={boothList[exhibitionHallNum]}
                  boothPusher={boothPusher}
                />
              )}
              {exhibitionHallStyle === "BOOTH_EXHIBITION_2D_02" && (
                <IndoorExhibition2DStyle2
                  boothList={boothList[exhibitionHallNum]}
                  openExhibitorList={openExhibitorList}
                  boothPusher={boothPusher}
                />
              )}
              {exhibitionHallStyle === "BOOTH_EXHIBITION_2D_03" && (
                <IndoorExhibition2DStyle3
                  boothList={boothList[exhibitionHallNum]}
                  boothPusher={boothPusher}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const IndoorExhibitionContainer = (props) => {
  return (
    <BoothContextProvider>
      <IndoorExhibition {...props} />
    </BoothContextProvider>
  );
};

IndoorExhibitionContainer.fragments = {
  indoorExhibitionSet: gql`
    fragment IndoorExhibitionContainerFragment on Event {
      ${indoorExhibition}
    }
  `,
};

export default IndoorExhibitionContainer;
