import React from "react";

const ConcurrentSession = ({ close, sessionList, setSelectedSession }) => {
  const select = (session) => {
    setSelectedSession((prev) => session);
    close();
  };
  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small zoomIn">
        <div className="modal__header">
          {/* <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button> */}
          <section className="modal__tit">
            <h2>Select Your Session Room</h2>
          </section>
        </div>
        <div className="modal__content npd">
          <div className="content-box s-session">
            <ul className="s-session__box">
              {sessionList
                ? sessionList.map((s) => {
                    return (
                      <li
                        key={s.code}
                        className="s-session__list"
                        onClick={() => select(s)}
                      >
                        <a className="s-session__link">
                          <p>
                            <b className="s-session__title">{s.title}</b>
                            <small className="s-session__location">
                              <ion-icon name="location"></ion-icon>
                              &ensp;
                              {s.place_type.charAt(0).toUpperCase() +
                                s.place_type.slice(1)}
                            </small>
                          </p>
                          <p className="s-session__desc">
                            {/* <small>Session Description</small> */}
                            <small>
                              {s.moderatorList ? (
                                s.moderatorList.length ? (
                                  <>
                                    <ion-icon name="person"></ion-icon>&ensp;
                                    {s.moderatorList[0].name}
                                  </>
                                ) : null
                              ) : null}
                            </small>
                          </p>
                        </a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcurrentSession;
