import React, { useMemo } from "react";
import ReactMoment from "react-moment";

const NotificationAlert = ({
  notificationMessage,
  setOpenNotificationAlert,
  joinRoom,
  setOpenMessenger,
  setHasNew,
}) => {
  const { _client, body } = notificationMessage;
  const { message, user_name, iat } = body;
  console.log("body ::", body);
  return (
    <>
      <div className="govent-push__new-message">
        <button
          className="govent-push__new-close"
          onClick={(e) => {
            setOpenNotificationAlert(false);
            setHasNew(false);
          }}
        ></button>
        <p className="govent-push__new-name">{user_name}</p>
        <p className="govent-push__new-date">
          <ReactMoment format="YYYY-MM-DD HH:mm">{iat}</ReactMoment>
        </p>
        <p
          className="govent-push__new-txt"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            // console.log("body ::", body);
            joinRoom(body);
            setOpenMessenger(true);
            setOpenNotificationAlert(false);
            setHasNew(false);
          }}
        >
          {message}
        </p>
      </div>
    </>
  );
};

export default NotificationAlert;
