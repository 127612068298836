import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import useStamp from "../../../../../hooks/useStamp";

const VideoOnly = ({ title: modal_title, selectedVod, close, setStamp }) => {
  // console.log("hi");
  // useEffect(() => {
  //   if (typeof setStamp === "function") {
  //     setStamp("lobby_vod");
  //   }
  // }, []);
  const { title, url } = selectedVod;
  return (
    <div className={(title || modal_title) && url ? "modal" : "modal hidden"}>
      <div className="modal__overlay"></div>
      <div className="modal__wrapper wide zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>
              {title ? title : modal_title ? modal_title : "VOD PLAYLIST"}
            </h2>
          </section>
        </div>
        <div className="modal__content">
          <div className="video-box" style={{ height: "100%" }}>
            <iframe
              src={
                url
                  ? url
                  : "https://player.vimeo.com/video/465663492?autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0"
              }
              frameBorder="0"
              allow="camera; microphone; autoplay; fullscreen"
              allowFullScreen
              style={{ width: "100%", height: "100%" }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoOnly;
