import React, { useEffect, useRef } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { ASSETS_HOST } from "../../config/define";
import { companyBoothSet, toggleBookmark } from "../../query/queries";
import { useLocation } from "react-router";

const TOGGLE_BOOKMARK = gql`
  ${toggleBookmark}
`;

const CompanyInfo = ({
  companyInfo = {},
  loginType,
  hasPrevButton,
  goToPrev,
  close,
  openAlert,
}) => {
  const client = useApolloClient();
  const [toggleBookmark, { loading, data }] = useMutation(TOGGLE_BOOKMARK);
  const location = useLocation();
  const optimisticValue = useRef(null);
  // const companyIdx = pathnameArr[pathnameArr.length - 1];

  const bookmark = (e) => {
    try {
      toggleBookmark({
        variables: { company_idx: Number(companyInfo.idx) },
        update: (cache, result) => {
          const { data } = result;
          if (data.toggleBookmark) {
            // const { company_idx } = data.toggleBookmark;
            cache.modify({
              id: cache.identify({
                id: `${companyInfo.booth_exhibition_idx}`,
                __typename: "BoothExhibition",
              }),
              fields: {
                companyList(currCompanyList, { readField }) {
                  const newCompanyList = [];
                  currCompanyList.forEach((c) => {
                    const isBookmarked = readField("is_bookmarked", c);
                    if (+c.idx === Number(companyInfo.idx)) {
                      optimisticValue.current =
                        isBookmarked === "Y" ? "N" : "Y";
                      isBookmarked === "Y"
                        ? newCompanyList.push({ ...c, is_bookmarked: "N" })
                        : newCompanyList.push({ ...c, is_bookmarked: "Y" });
                    } else {
                      newCompanyList.push(c);
                    }
                  });
                  return newCompanyList;
                },
              },
            });
          }
        },
      });
    } catch (err) {
      console.log("toggle bookmark err ::", err);
    }
  };

  useEffect(() => {
    if (optimisticValue.current && !loading && data) {
      if (optimisticValue.current === "N") {
        openAlert({ bool: true, msg: "the bookamrk is deleted." });
      } else if (optimisticValue.current === "Y") {
        openAlert({ bool: true, msg: "the company is bookmarked." });
      }
    }
  }, [loading, data]);

  return (
    <div className="modal popup company-info" id="company-info">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          {hasPrevButton ? (
            <button className="back" onClick={goToPrev}>
              <ion-icon name="arrow-back"></ion-icon>
            </button>
          ) : null}

          <section className="company-info__header">
            <aside className="company-info__logo">
              <img
                src={
                  companyInfo?.logo_url ||
                  "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                  // `${ASSETS_HOST}/logo/sto_logo_eng.png`
                }
                alt="Company Logo"
              />
            </aside>
            <main className="company-info__content">
              <h3 className="company-info__name">
                {companyInfo?.organization}
              </h3>
              {!location.pathname.includes("/exhibition/booth/") &&
              loginType !== "unlogin" ? (
                <button className="company-info__bookmark" onClick={bookmark}>
                  Bookmark
                </button>
              ) : null}

              <div className="company-info__info">
                <dl>
                  <dt>Representative</dt>
                  <dd>{companyInfo?.ceo_name}</dd>
                </dl>
                <dl>
                  <dt>Address</dt>
                  <dd>
                    {companyInfo?.address ? (
                      <a
                        className="company-info__address"
                        href={companyInfo?.map_url}
                        target="_blank"
                      >
                        {companyInfo?.address}
                      </a>
                    ) : null}
                  </dd>
                </dl>
                <dl>
                  <dt>Web Site</dt>
                  <dd>
                    {companyInfo?.website_url ? (
                      <a
                        className="company-info__site"
                        href={companyInfo.website_url}
                        target="_blank"
                      >
                        {companyInfo.website_url}
                      </a>
                    ) : null}
                  </dd>
                </dl>
                <dl>
                  <dt>Contact</dt>
                  <dd>{companyInfo?.company_mobile_no}</dd>
                </dl>
                <dl>
                  <dt>E-mail</dt>
                  <dd>{companyInfo?.email}</dd>
                </dl>
              </div>
            </main>
          </section>
        </div>
        <div className="modal__content">
          <div className="content-box">
            <section className="company-info__desc">
              <pre>{companyInfo?.abstract}</pre>
            </section>
            <section className="company-info__footer">
              {companyInfo?.companySnsList ? (
                <div className="listing-sns">
                  {companyInfo?.companySnsList.map((v) => {
                    return v.active_yn === "Y" ? (
                      <a
                        key={v.link_url}
                        href={v.link_url}
                        target="_blank"
                        className="company-info__footer--sns"
                      >
                        <ion-icon name={`logo-${v.type}`}></ion-icon>
                      </a>
                    ) : null;
                  })}
                </div>
              ) : null}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
