import { gql } from "@apollo/client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CompanyInfo from "../../components/exhibition/CompanyInfo";
import ExhibitionNavigator from "../../components/exhibition/ExhibitionNavigator";
import ExhibitorList from "../../components/exhibition/type/ExhibitorList";
import { BoothContext, BoothContextProvider } from "../../context/BoothContext";
import { ModalPortalContext } from "../../context/ModalContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import useResize from "../../hooks/useResize";
import { indoorExhibition } from "../../query/queries";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import define2D from "../../config/define2D";

const Booth01Pusher = styled.div`
  position: absolute;
  top: 178px;
  left: 150px;
  width: 430px;
  height: 300px;
  font-size: 3rem;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-4.5deg);
  -webkit-transform: rotate(-4.5deg);
  -ms-transform: rotate(-4.5deg);

  .boothTitle {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 203px;
    width: 219px;
    top: -83px;
    color: black;
    transform: skewX(5deg);
    -webkit-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    font-size: 80%;
    p {
      text-align: center;
    }
  }
  // text-align: center;
  .logoUrl {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 102px;
    width: 124px;
    height: 35px;
    left: 8px;
    transform: skewX(7deg);
    -webkit-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 139px;
    left: 29px;
    width: 40px;
    height: 55px;
    transform: skewX(7deg);
    -webkit-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth02Pusher = styled.div`
  position: absolute;
  top: 140px;
  left: 700px;
  width: 400px;
  height: 300px;
  font-size: 3rem;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-4.5deg);
  -webkit-transform: rotate(-4.5deg);
  -ms-transform: rotate(-4.5deg);

  .boothTitle {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 176px;
    width: 211px;
    top: -82px;
    color: black;
    transform: skewX(-5deg);
    -webkit-transform: skewX(-5deg);
    -ms-transform: skewX(-5deg);
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    font-size: 74%;
    p {
      text-align: center;
    }
  }
  .logoUrl {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 100px;
    left: 20px;
    width: 117px;
    height: 33.5px;
    transform: rotate(0.2deg);
    -webkit-transform: rotate(0.2deg);
    -ms-transform: rotate(0.2deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      max-height: 100%;
    }
  }
  .boothBanner {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 136px;
    left: 28px;
    width: 39px;
    height: 54px;
    transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth03Pusher = styled.div`
  position: absolute;
  top: 100px;
  left: 1180px;
  width: 400px;
  height: 300px;
  font-size: 3rem;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-4.5deg);
  -webkit-transform: rotate(-4.5deg);
  -ms-transform: rotate(-4.5deg);

  .boothTitle {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 195px;
    width: 200px;
    top: -77px;
    color: black;
    transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    font-size: 71%;
    p {
      text-align: center;
    }
  }
  .logoUrl {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 74px;
    top: 103px;
    width: 112px;
    height: 34px;
    transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 138px;
    left: 70px;
    width: 38px;
    height: 55px;
    transform: skew(-8deg, 0.5deg);
    -webkit-transform: skew(-8deg, 0.5deg);
    -ms-transform: skew(-8deg, 0.5deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth04Pusher = styled.div`
  position: absolute;
  top: 590px;
  left: 60px;
  width: 550px;
  height: 400px;
  font-size: 3rem;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);

  .boothTitle {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: -86px;
    color: black;
    left: 268px;
    width: 274px;
    transform: skewX(5deg);
    -webkit-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    font-size: 80%;
    p {
      text-align: center;
    }
  }
  .logoUrl {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 174px;
    left: 10px;
    width: 160px;
    height: 39px;
    transform: skewX(11deg) rotate(-0.5deg);
    -webkit-transform: skewX(11deg) rotate(-0.5deg);
    -ms-transform: skewX(11deg) rotate(-0.5deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    //background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 213px;
    left: 39px;
    width: 52px;
    height: 62px;
    transform: skewX(9deg);
    -webkit-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth05Pusher = styled.div`
  position: absolute;
  top: 530px;
  left: 750px;
  width: 520px;
  height: 400px;
  font-size: 3rem;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);

  .boothTitle {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: -78px;
    color: black;
    left: 233px;
    width: 259px;
    transform: skewX(-5deg);
    -webkit-transform: skewX(-5deg);
    -ms-transform: skewX(-5deg);
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    font-size: 80%;
    p {
      text-align: center;
    }
  }
  .logoUrl {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 168px;
    left: 39px;
    width: 149px;
    height: 35px;
    transform: skewX(-3deg) rotate(-0.5deg);
    -webkit-transform: skewX(-3deg) rotate(-0.5deg);
    -ms-transform: skewX(-3deg) rotate(-0.5deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 211px;
    left: 47px;
    width: 50px;
    height: 58px;
    transform: skewX(-3deg);
    -webkit-transform: skewX(-3deg);
    -ms-transform: skewX(-3deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth06Pusher = styled.div`
  position: absolute;
  top: 470px;
  left: 1360px;
  width: 500px;
  height: 370px;
  font-size: 3rem;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);

  .boothTitle {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: -71px;
    color: black;
    left: 248px;
    width: 240px;
    transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    font-size: 80%;
    p {
      text-align: center;
    }
  }
  .logoUrl {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 168.5px;
    left: 103px;
    width: 141px;
    height: 35px;
    transform: skew(-13deg) rotate(-0.3deg);
    -webkit-transform: skew(-13deg) rotate(-0.3deg);
    -ms-transform: skew(-13deg) rotate(-0.3deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    top: 209px;
    left: 92px;
    width: 46px;
    height: 58px;
    transform: skewX(-13deg);
    -webkit-transform: skewX(-13deg);
    -ms-transform: skewX(-13deg);
    img {
      border-radius: 3px;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;

const IndoorExhibition2DStyle1 = ({
  eventInfoCompleted,
  indoorExhibitionSet,
  loginType,
  error: indoorExhibitionSetError,
  boothPusher,
  boothList,
}) => {
  const { user } = useContext(UserLoginContext);

  // const { boothList, categoryList, companyInfo, refetch } =
  //   useContext(BoothContext);
  // const { triggerModal } = useContext(ModalPortalContext);

  useEffect(() => {
    if (indoorExhibitionSetError) {
      setOnLazyLoading(false);
      setIsLoading(false);
      setStamp("exhibition_booth_visit");
    }
  }, [indoorExhibitionSetError]);

  return (
    <>
      <img
        alt="exhibition BG image"
        src={define2D.RESOURCE.ExhibitionHall.Style1}
        className="lobby__background"
      />
      <Booth01Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[0]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[0]?.companyBooth[0]?.logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[0]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothTitle">
          <p>
            {/* <p>일이삼사오육칠팔구십</p> */}
            {boothList?.companyList
              ? boothList?.companyList[0]?.companyBooth[0]?.booth_title
              : null}
          </p>
        </div>
      </Booth01Pusher>
      <Booth02Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[1]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[1]?.companyBooth[0]?.logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[1]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothTitle">
          {/* <p>일이삼사오육칠팔구십</p> */}
          <p>
            {boothList?.companyList
              ? boothList?.companyList[1]?.companyBooth[0]?.booth_title
              : null}
          </p>
        </div>
      </Booth02Pusher>
      <Booth03Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[2]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[2]?.companyBooth[0]?.logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[2]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothTitle">
          {/* <p>일이삼사오육칠팔구십</p> */}
          <p>
            {boothList?.companyList
              ? boothList?.companyList[2]?.companyBooth[0]?.booth_title
              : null}
          </p>
        </div>
      </Booth03Pusher>
      <Booth04Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[3]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[3]?.companyBooth[0]?.logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[3]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothTitle">
          {/* <p>일이삼사오육칠팔구십</p> */}
          <p>
            {boothList?.companyList
              ? boothList?.companyList[3]?.companyBooth[0]?.booth_title
              : null}
          </p>
        </div>
      </Booth04Pusher>
      <Booth05Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[4]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[4]?.companyBooth[0]?.logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[4]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothTitle">
          {/* <p>일이삼사오육칠팔구십</p> */}
          <p>
            {boothList?.companyList
              ? boothList?.companyList[4]?.companyBooth[0]?.booth_title
              : null}
          </p>
        </div>
      </Booth05Pusher>
      <Booth06Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[5]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[5]?.companyBooth[0]?.logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[5]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothTitle">
          {/* <p>일이삼사오육칠팔구십</p> */}
          <p>
            {boothList?.companyList
              ? boothList?.companyList[5]?.companyBooth[0]?.booth_title
              : null}
          </p>
        </div>
      </Booth06Pusher>
    </>
  );
};

const IndoorExhibitionContainer = (props) => {
  return (
    <BoothContextProvider>
      <IndoorExhibition2DStyle1 {...props} />
    </BoothContextProvider>
  );
};

export default IndoorExhibitionContainer;
