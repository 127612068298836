import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ModalPortalContext } from "../../context/ModalContext";
import { SocketContext } from "../../context/SocketContext";
// import io from "socket.io-client";
import { UserLoginContext } from "../../context/UserLoginContext";
import util from "../../util/index";
import "./govent-push.css";
import Messenger from "./Messenger";
import NotificationAlert from "./NotificationAlert";

const PUSH_HOST = process.env.REACT_APP_PUSH_HOST; //"https://push.govent.io";
// console.log("PUSH_HOST ::", PUSH_HOST);
let isEndOfPage = true;

const PushWidget = () => {
  const { openAlert } = useContext(ModalPortalContext);
  const { getUser, getToken } = useContext(UserLoginContext);
  const { socket, currentRoom, setCurrentRoom } = useContext(SocketContext);
  const [openNotificationAlert, setOpenNotificationAlert] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({});
  const [openMessenger, setOpenMessenger] = useState(false); // messenger open 여부
  const [inRoom, setInRoom] = useState(false); // 방에 입장 여부 inRoom 이랑 currentRoomRef.current 의 값이 {} 인지로 판단함.
  const [hasNew, setHasNew] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const currentRoomRef = useRef(currentRoom);
  const [roomHistory, setRoomHistory] = useState([]);
  const [myMessage, setMyMessage] = useState("");
  const [isDirectJoin, setDirectJoin] = useState(false);
  const myMessageRef = useRef("");
  const socketRef = useRef(null); // 화면값 아님.
  const user = getUser(); // { name: ddd, email: ddd }
  const token = getToken(); // { name: ddd, email: ddd }

  const myId = user.email; //"si.kim@salin.co.kr"; // logined user id
  const myName = user.name; //"김성일"; // logined user name

  // const setMyMessage = (v) => (myMessageRef.current = v);

  const sendMyMessage = (e) => {
    if (!myMessage.length) return;

    const payload = currentRoomRef.current;
    payload.sender_id = myId;
    payload.sender_name = myName;
    payload.message = myMessage;
    socket.emit("message", payload);
    setMyMessage("");
  };

  const addRoom = (room) => {
    setRoomList((prev) => {
      if (prev === undefined) return [room]; // 비어 있는 경우.

      const { type, host_id, guest_name, host_name, room_title } = room;

      if (type === "individual") {
        const isMine = host_id === myId;
        room.room_title = isMine ? guest_name : host_name;
      }

      if (type === "notice") {
        room.room_title = room_title;
      }

      const index = prev.findIndex((item) => {
        // console.log("item ::", item);
        return item.room_code === room.room_code;
      });
      if (index === -1) {
        if (!room.room_title) {
          room.new_message = true;
          room.room_title = room.user_name; // 신규 메세지로 방 생성 시 상대방 이름으로 방을 만든다.
        }

        return [...prev, room];
      } else {
        prev[index].last_iat = room.last_iat;
        prev[index].last_message = room.last_message;
        return [...prev];
      }
    });
  };

  const addRoomHistory = (payload) => {
    const { body } = payload;
    const { room_code, messageId, message } = body;

    // 채팅 리스트
    setRoomList((prev) => {
      // new_message
      if (prev === undefined) return []; // 비어 있는 경우.

      prev.map((item, index) => {
        if (item.room_code === room_code) {
          item.last_message = message;

          if (currentRoomRef.current.room_code !== room_code) {
            item.new_message = true;
          }
        }
      });
      return [...prev];
    });
    // console.log("room_code!! ::", room_code);
    // console.log(
    //   "currentRoomRef.current.room_code ::",
    //   currentRoomRef.current.room_code
    // );
    if (currentRoomRef.current.room_code === room_code) {
      setRoomHistory((prev) => {
        if (prev === undefined) return [payload]; // 비어 있는 경우.
        // console.log("prev ::", prev);

        if (prev.length >= 50) prev.shift(); // 50개 표시 -> 성능 이슈.

        const index = prev.findIndex((item) => {
          return item.body.messageId === messageId;
        });
        if (index === -1) {
          return [...prev, payload];
        } else {
          return [...prev];
        }
      });
      // 자동 스크롤.
      // console.log("isEndOfPage ::", isEndOfPage);
      if (isEndOfPage) {
        setTimeout(() => {
          const chatList = document.getElementById("govent_push_chatLog");
          // console.log('chatList ::', chatList);
          if (chatList) {
            const { scrollHeight } = chatList;
            chatList.scrollTop = scrollHeight;
          }
        }, 100);
      }
    }
    if (room_code === "notice") {
      setRoomHistory((prev) => {
        if (prev === undefined) return [payload]; // 비어 있는 경우.
        // console.log("prev ::", prev);

        if (prev.length >= 50) prev.shift(); // 50개 표시 -> 성능 이슈.

        const index = prev.findIndex((item) => {
          return item.body.messageId === messageId;
        });
        if (index === -1) {
          return [...prev, payload];
        } else {
          return [...prev];
        }
      });
      // 자동 스크롤.
      // console.log("isEndOfPage ::", isEndOfPage);
      if (isEndOfPage) {
        setTimeout(() => {
          const chatList = document.getElementById("govent_push_chatLog");
          // console.log('chatList ::', chatList);
          if (chatList) {
            const { scrollHeight } = chatList;
            chatList.scrollTop = scrollHeight;
          }
        }, 100);
      }
    }
  };

  const requestRoomList = (e) => {
    socket.emit("requestRoomList");
  };

  const joinRoom = (room) => {
    const {
      type,
      // room_code,
      // last_iat,
      // last_message,
      host_id,
      guest_name,
      host_name,
      // room_title,
    } = room;
    // console.log("currentRoom ::", currentRoom);
    if (type !== "notice") room.room_title = `${host_name}_${guest_name}`;
    setRoomHistory([]); // room 이 바뀔 때 마다 새로 받아서 처리함.
    setMyMessage("");
    console.log("room ::", room);

    if (
      !currentRoomRef.current ||
      currentRoomRef.current?.room_code !== room.room_code
    ) {
      socket.emit("join", room, (v) => {
        if (Object.keys(v).length) {
          // console.log("v ::", v);
          console.log("join success", v);
          currentRoomRef.current = v;
          setCurrentRoom(v);
          return;
        }
        openAlert({ bool: true, msg: "방 입장에 실패했습니다." });
      });
    }
    setInRoom(true); // rendering value가 아니면 접근할 수 없음. ref 사용해야하나??
  };

  const exitRoom = () => {
    currentRoomRef.current = {};
    setCurrentRoom({});
    setInRoom(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on("roomList", (payload) => {
        // console.log("roomList ::", payload);
        addRoom(payload.body);
      });

      socket.on("joined", (payload) => {
        // console.log("joined payload :: ", payload);
        const { _client, body } = payload;
        const { user_id, user_name } = body;
        // body.messageId =
        //   Date.now().toString() + "_" + Math.floor(Math.random() * 100000 + 1);
        // body.message = `${user_name} joined the room.`;
        // payload.isMine = myId === user_id;
        // console.log("user_id ::", user_id);
        // console.log("type ::", type);
        if (!openMessenger && user.email === user_id) {
          setOpenMessenger(true);
          setDirectJoin(true);
        }
        // addRoomHistory(payload); // 본인 또는 신규 User가 입장한 경우.
      });

      socket.on("roomHistory", (payload) => {
        // console.log("roomHistory :: ", payload);
        const { _client, body } = payload; // body type is array.
        // const { userId } = body;
        body.map((item, index) => {
          const { userId, room_code } = item;
          const payload2 = {};
          payload2._client = payload._client;
          payload2.body = item;
          payload2.isMine = room_code === "notice" ? false : myId === userId;
          addRoomHistory(payload2);
        });
      });

      socket.on("message", (payload) => {
        if (socket.connected === false) return;
        // console.log("message payload :: ", payload, " inRoom ::", inRoom);
        const { _client, body } = payload;
        const { user_id } = body;
        payload.isMine = myId === user_id;

        setInRoom((prev) => {
          // console.log("message setInRoom prev ::", prev);
          if (prev) {
            addRoom(body);
            addRoomHistory(payload);
          }
          return prev;
        });

        setOpenMessenger((prev) => {
          console.log("setOpenMessenger ::", prev);
          // console.log("inRoom ::", inRoom);
          // 대화방 내에 있는 지 대화 목록에 있는 지 확인 필요.
          // util.isEmptyObject(currentRoomRef.current) === inRoom is false
          // console.log("currentRoomRef.current ::", currentRoomRef.current);
          if (prev === true && util.isEmptyObject(currentRoomRef.current)) {
            // 열려있고 대기실에 있음. -> 대화방 목록에서 새로운 메세지 도착 표시필요.
            // addRoomHistory(payload); // 이거 넣으니까 2번 호출됨. 흠.ㅠ.ㅜ
            // window.alert(
            //   "새로운 메세지 도착 / 대화방 목록 화면에 있음 / 디자인 필요\n\n" +
            //     payload.body.message
            // );
            requestRoomList();
          } else if (prev === false && payload.isMine === false) {
            // console.log("payload ::", payload);
            // 닫혀있고 내가 보낸 게 아님.`
            setNotificationMessage(payload);
            setHasNew(true);
            setOpenNotificationAlert(true);
          }
          return prev;
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    // if (openMessenger && currentRoom.room_code && isDirectJoin) {
    //   joinRoom(currentRoom);
    //   setDirectJoin(false);
    // }
    if (openMessenger && Object.keys(currentRoom).length && isDirectJoin) {
      joinRoom(currentRoom);
      setDirectJoin(false);
    }
  }, [openMessenger, currentRoom, isDirectJoin]);

  if (util.isEmptyObject(user)) {
    return null;
  }

  return (
    <>
      <div className="govent-push">
        <div
          className="govent-push__noti-btn"
          onClick={(e) => {
            setOpenMessenger(true); // 닫는 건 Messenger Unmounted에서 false 함.
          }}
        >
          <div className="govent-push__icon">
            <img src="/assets/notice/notice.png" />
          </div>
          {hasNew && <div className="govent-push__new-mark"></div>}
        </div>
        {openNotificationAlert && (
          <NotificationAlert
            notificationMessage={notificationMessage}
            setOpenNotificationAlert={setOpenNotificationAlert}
            joinRoom={joinRoom}
            setOpenMessenger={setOpenMessenger}
            setHasNew={setHasNew}
          />
        )}
        {openMessenger && (
          <Messenger
            openMessenger={openMessenger}
            setOpenMessenger={setOpenMessenger}
            roomList={roomList}
            requestRoomList={requestRoomList}
            joinRoom={joinRoom}
            currentRoom={currentRoom}
            inRoom={inRoom}
            exitRoom={exitRoom}
            roomHistory={roomHistory}
            sendMyMessage={sendMyMessage}
            setMyMessage={setMyMessage}
            setDirectJoin={setDirectJoin}
            myMessage={myMessage}
            myId={myId}
            myName={myName}
          />
        )}
      </div>
    </>
  );
};
// window.PushWidget = PushWidget;

export default React.memo(PushWidget, (prevProps, nextProps) => {
  // prevProps.users === nextProps.users;
  // console.log("prevProps ::", prevProps);
  // console.log("nextProps ::", nextProps);
  return true;
});
