export const virtualPlaceNavs = {
  open_stage_name: {
    icon: "earth",
    path: "/openstage",
  },
  conference_name: {
    icon: "people",
    path: "/conference",
  },
  workshop_name: {
    icon: "briefcase",
    path: "/workshop",
  },
  outdoor_name: {
    icon: "sunny",
    path: "/exhibition/outdoor",
  },
  exhibition_name: {
    icon: "cube",
    path: "/exhibition/indoor",
  },
  business_room_name: {
    icon: "people",
    path: "/business",
  },
  theater_name: {
    icon: "logo-youtube",
    path: "/theater",
  },
  seoul_booth_name: {
    icon: "megaphone",
    path: "/booth",
  },
  lounge_name: {
    icon: "share-social",
    path: "/lounge",
  },
  lobby_name: {
    icon: "home",
    path: "/",
  },
};
