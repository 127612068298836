import React, { createContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import ModalPortal from "./../components/common/modal/ModalPortal";
import Alert from "../components/common/modal/Alert";

const ModalPortalContext = createContext();
const initialState = {
  content: {
    title: "",
    Component: null,
    hasOwnContainer: false,
  },
  modalProps: {
    onClosed: undefined,
  },
};

const ModalPortalProvider = ({ Container, children }) => {
  const [isModalOpen, openModal] = useState(false);
  const [alert, setAlert] = useState({
    bool: false,
    msg: "",
  });
  const closeAlertDisabled = React.useRef(false);
  const [modalProps, setModalProps] = useState(initialState.modalProps);
  const [content, setContent] = useState(initialState.content);
  const [hasHeader, setHasHeader] = useState(true);
  const [hasPrevButton, setHasPrevButton] = useState(true);
  const { Component } = content;
  const triggerModal = React.useCallback((options) => {
    const {
      bool,
      props = {},
      content = initialContent,
      hasPrevButton = true,
    } = options;
    openModal(bool);
    setModalProps(props);
    setContent(content);
    setHasPrevButton(hasPrevButton);
  }, []);

  const goToPrev = () => {
    if (modalProps.onPrev) modalProps.onPrev();
  };

  const openAlert = (obj) => {
    if (closeAlertDisabled.current === true) return;
    if (obj.disableCloseAlert && closeAlertDisabled.current === false) {
      closeAlertDisabled.current = true;
    }
    setAlert(obj);
  };

  const close = () => {
    Promise.all([
      openModal(false),
      setModalProps({}),
      setContent(initialState.content),
      setHasPrevButton(false),
    ])
      .then((_) => {
        if (modalProps.onClosed) {
          modalProps.onClosed();
        }
      })
      .catch((err) => console.error("modal close error ::", err.message));
  };

  const value = { isModalOpen, triggerModal, openAlert };

  return (
    <ModalPortalContext.Provider value={value}>
      {isModalOpen && (
        <ModalPortal>
          {!Component ? null : !content.hasOwnContainer ? (
            <Container
              title={content.title}
              hasHeader={hasHeader}
              hasPrevButton={hasPrevButton}
              close={close}
              openAlert={openAlert}
              goToPrev={goToPrev}
              video={content.video}
            >
              <Component {...modalProps} />
            </Container>
          ) : (
            <Component
              {...modalProps}
              title={content.title}
              goToPrev={goToPrev}
              close={close}
              openAlert={openAlert}
              hasPrevButton={hasPrevButton}
            />
          )}
        </ModalPortal>
      )}
      {alert.bool && (
        <ModalPortal>
          <Alert
            alert={alert}
            onClose={
              closeAlertDisabled.current === true
                ? null
                : () => {
                    openAlert({ bool: false, msg: "" });
                  }
            }
          />
        </ModalPortal>
      )}

      {children}
      <div id="modal-root"></div>
    </ModalPortalContext.Provider>
  );
};

ModalPortalProvider.propTypes = {
  Container: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export { ModalPortalContext, ModalPortalProvider };
