import React, { useState, useEffect, useContext, useRef } from "react";
import { ModalPortalContext } from "../../context/ModalContext";
import { gql, useLazyQuery } from "@apollo/client";
import LoungeUserID from "./LoungeUserID";

const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      name
      email
      job_title
      organization
      country
    }
  }
`;

const NetworkLoungeTable = ({
  user,
  socket,
  setCurrentRoom,
  openAlert,
  close,
}) => {
  console.log("setCurrentRoom", setCurrentRoom);
  const [getCurrentUser, { error, data, loading, refetch }] =
    useLazyQuery(CURRENT_USER);
  const [currentUser, setCurrentUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchedUser, setSearchedUser] = useState([]);
  const [subModal, setSubModal] = useState("");
  const searchInput = useRef("");
  const viewIDCard = (user) => {
    setSelectedUser(user);
    setSubModal(true);
  };

  const refresh = () => {
    if (!loading) refetch();
  };

  const openChat = (e) => {
    const { id: guest_id, name: guest_name } = e.currentTarget.dataset;
    const { email: host_id, name: host_name } = user;
    if (!socket || !guest_id || !guest_name || !host_id || !host_name)
      return null;
    const roomInfo = {
      host_id,
      host_name,
      guest_id,
      guest_name,
      type: "individual",
      is_read_only: "N",
      room_title: `${host_name}_${guest_name}`,
    };
    socket.emit("join", roomInfo, (v) => {
      if (Object.keys(v).length) {
        setCurrentRoom(v);
        return;
      }
      openAlert({ bool: true, msg: "방 입장에 실패했습니다." });
    });
  };

  const closeIDCard = () => {
    setSubModal("");
    setSelectedUser({});
  };

  const search = () => {
    if (!currentUser.length) return null;
    if (!searchInput.current.value) {
      return setSearchedUser(currentUser);
    }
    setSearchedUser(
      currentUser.filter((u) => searchInput.current.value.includes(u.name)) ||
        []
    );
    searchInput.current.value = "";
  };

  const onInputChanged = (e) => {
    keyword.current = e.target.value;
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setCurrentUser(data.currentUser);
      setSearchedUser(data.currentUser);
    }
  }, [loading, data]);

  return (
    <>
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__wrapper wide zoomIn">
          <div className="modal__header">
            <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button>
            <section className="modal__tit">
              <h2>1:1 Meetings With Delegates</h2>
              <p>
                You can check other attendees’ business card and invite them to
                1:1 meetings here.
              </p>
            </section>
          </div>
          <div className="modal__content hd-dsc-l pd0">
            <div className="content-box lounge-chatting">
              <div className="top">
                <section className="listing-attendee__title">
                  <ul className="listing-attendee__list">
                    <li className="listing-attendee__cell">No</li>
                    <li className="listing-attendee__cell">Name</li>
                    <li className="listing-attendee__cell">Organization</li>
                    <li className="listing-attendee__cell">Country</li>
                    <li className="listing-attendee__cell">Contact</li>
                  </ul>
                </section>
                <ul className="listing-attendee">
                  {searchedUser.map((c, i) => {
                    const { name, email, country, organization } = c;
                    const _viewIDCard = () => viewIDCard(c);
                    return (
                      <li className="listing-attendee__list" key={email}>
                        <div className="listing-attendee__cell">
                          <p>{i.length > 1 ? i : "0" + (i + 1)}</p>
                        </div>
                        <div className="listing-attendee__cell">
                          <p className="name">{name}</p>
                        </div>
                        <div className="listing-attendee__cell">
                          <p className="organization">{organization}</p>
                        </div>
                        <div className="listing-attendee__cell">
                          <p className="country">{country}</p>
                        </div>
                        <div className="listing-attendee__cell">
                          <button
                            type="button"
                            className="idcard-btn"
                            data-modal="profile-info"
                            onClick={_viewIDCard}
                          >
                            <ion-icon name="id-card-outline"></ion-icon>
                          </button>
                          <button
                            type="button"
                            className="chat-btn"
                            data-id={email}
                            data-name={name}
                            onClick={openChat}
                          >
                            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="bottom">
                <button
                  className="refresh"
                  value="“Reload”"
                  type="button"
                  onClick={refresh}
                >
                  <ion-icon name="refresh"></ion-icon>
                </button>
                <div className="search-box">
                  <button className="search-btn" onClick={search}>
                    <ion-icon name="search-outline"></ion-icon>
                  </button>
                  <input
                    ref={searchInput}
                    type="text"
                    className="input-search"
                    placeholder="Type to Search"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {subModal ? (
        <LoungeUserID user={selectedUser} close={closeIDCard} />
      ) : null}
    </>
  );
};

export default NetworkLoungeTable;
