import React from "react";

const Survey = ({ url, close }) => {
  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper wide zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>Survey</h2>
            <p>Please provide your feedback.</p>
          </section>
        </div>
        <div className="modal__content hd-dsc sv-npd">
          <div className="content-box h100">
            <iframe
              src={url}
              allow="camera; microphone; autoplay; fullscreen"
              allowFullScreen
              style={{ width: "100%", height: "100%" }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
