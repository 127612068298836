import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { virtualPlaceNavs } from "../../config/virtualPlaceNavigation";

const NavItem = ({ path, name }) => {
  const history = useHistory();
  const location = useLocation();

  const goTo = () => {
    history.push(virtualPlaceNavs[path].path, {
      from: location.pathname,
    });
  };

  if (!name) return <></>;
  return (
    <li
      key={path}
      className={
        location.pathname !== virtualPlaceNavs[path].path
          ? "nav__item--2D"
          : "nav__item--2D active"
      }
    >
      <a className="nav__link--2D" onClick={goTo}>
        <ion-icon
          name={virtualPlaceNavs[path].icon}
          class="nav__link--icon--2D"
        ></ion-icon>
        <span className="nav__link--txt--2D">{name}</span>
      </a>
    </li>
  );
};

export default NavItem;
