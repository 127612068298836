import { useCallback, useMemo } from "react";
import { useState, useEffect, useRef } from "react";

export const useTimeWorker = () => {
  const worker = useRef(null);
  const isWorkerInitiated = useRef(false);
  const actions = useRef(new Map());
  const setWorker = (w) => (worker.current = w);
  const setIsWorkerInitiated = (bool) => (isWorkerInitiated.current = bool);
  const hasWorkerIntiated = () => isWorkerInitiated.current;

  const postMessage = useCallback(async (data) => {
    if (!isWorkerInitiated.current) return;
    worker.current.postMessage(data);
  }, []);

  const setOnMessageAction = useCallback((type, action) => {
    if (actions.current.get(type)) {
      return;
    }
    actions.current = new Map([...actions.current, [type, action]]);
  }, []);

  const close = useCallback(() => {
    if (!worker.current) return null;
    worker.current.postMessage({ type: "SLEEP", payload: "" });
    worker.current.terminate();
  }, []);

  useEffect(() => {
    if (window.Worker) {
      setWorker(new Worker("/workers/timer.js"));
      setIsWorkerInitiated(true);
    } else {
      console.log("web worker not supported.");
      // setInterval
    }
    return () => {
      if (worker.current) {
        worker.current.postMessage({
          type: "SLEEP",
          payload: {
            immediate: true,
            done: true,
          },
        });
        worker.current.terminate();
      }
    };
  }, []);

  useEffect(() => {
    if (worker.current) {
      worker.current.onmessage = async (e) => {
        const { data } = e;
        if (Object.keys(data).length) {
          const { type, idx } = data;
          if (actions.current.has(type)) {
            const fn = actions.current.get(type);
            fn(idx);
          }
        }
      };
    }
  }, []);

  const obj = {
    close,
    postMessage,
    setOnMessageAction,
    setIsWorkerInitiated,
    hasWorkerIntiated,
  };
  return obj;
};
