import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { gql } from "@apollo/client";
import define from "../../config/define";
import * as BABYLON from "@babylonjs/core";
import BaseScene from "../../components/webgl/BaseScene";
import WebGLUtil from "../../util/webgl";
import { ModalPortalContext } from "../../context/ModalContext";
import Brochure from "../../components/tower/Brochure";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import StampTour from "../../components/stamp/StampTour";
import { seoulBooth } from "../../query/queries";
import util from "../../util";
import ChannelService from "../../util/ChannelService";
import { UserLoginContext } from "../../context/UserLoginContext";

const SeoulNTower = ({
  error: seoulBoothSetError,
  eventInfoCompleted,
  seoulBoothSet,
  channelTalk,
  setStamp,
}) => {
  const { triggerModal } = useContext(ModalPortalContext);
  const { user } = useContext(UserLoginContext);
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [onLazyLoading, setOnLazyLoading] = useState(true);
  const [isSeoulBoothApplied, setIsSeoulBoothApplied] = useState(false);
  const sceneRef = useRef(null);
  const enabledMeshes = {
    N_Seoul_Tower_Brochure_Group: {
      id: "brochureList",
      callback: (brochureList) => () => {
        setStamp("seoul_booth_brochure");
        triggerModal({
          bool: true,
          props: {
            brochureList,
          },
          content: {
            title: "Brochure",
            Component: (props) => {
              return <Brochure {...props} />;
            },
            hasOwnContainer: true,
          },
          hasPrevButton: false,
        });
      },
    },
    N_Seoul_Tower_Screen_display: {
      id: "screen_video",
      callback: (url) => () => {
        triggerModal({
          bool: true,
          props: {
            selectedVod: { url: util.vimeoVodUrl(url) },
          },
          content: {
            title: "SCREEN VIDEO",
            Component: (props) => {
              return <VideoOnly {...props} />;
            },
            hasOwnContainer: true,
          },
          hasPrevButton: false,
        });
      },
      material: {
        id: "screen_video",
        type: "video",
      },
    },
    N_Seoul_Tower_Kiosk01_display: {
      id: "kiosk_left_link",
      callback: (link) => () => {
        window.open(link, "_blank");
      },
      material: {
        id: "kiosk_left_cover",
        type: "emissive",
      },
    },
    N_Seoul_Tower_Kiosk02_display: {
      id: "kiosk_right_link",
      callback: (link) => () => {
        window.open(link, "_blank");
      },
      material: {
        id: "kiosk_right_cover",
        type: "emissive",
      },
    },
    N_Seoul_Tower_Desk_logo1: {
      material: {
        id: "front_left_logo",
        type: "emissive",
        albeldo: true,
      },
    },
    N_Seoul_Tower_Desk_logo2: {
      material: {
        id: "front_right_logo",
        type: "emissive",
        albeldo: true,
      },
    },
    N_Seoul_Tower_Female: {
      notEnabledIf: () => {
        return channelTalk.yn === "N" || !channelTalk.key;
      },
      callback: () => () => {
        ChannelService.boot({
          pluginKey: channelTalk.key,
          profile: Object.keys(user).length
            ? {
                name: user.name,
                mobileNumber: user.mobile_no,
                CUSTOM_VALUE_1: user.email,
                CUSTOM_VALUE_2: user.title,
              }
            : {},
        });
      },
    },
    Signboard: {
      material: {
        id: "top_roller_banner",
        type: "emissive",
      },
    },
  };
  const onSceneReady = useCallback((scene) => {
    window.scene = scene;
    sceneRef.current = scene;
    const camera = WebGLUtil.Camera.set("free", {
      name: "conf camera",
      position: new BABYLON.Vector3(0, 0, 0),
      scene,
    });

    // camera.target = BABYLON.Vector3(0, 0, 0);
    camera.minZ = 1;
    camera.maxZ = 30000;
    camera.angularSensibility = 2000;
    camera.invertRotation = true;
    camera.speed = 5;
    camera.fov = 70;
    camera.rotation.y = -Math.PI;

    scene.createDefaultEnvironment({});
    scene.materials[0].imageProcessingConfiguration.toneMappingEnabled = false;
    camera.attachControl(true);

    const SuccessCallback = (ms) => {
      const arrayMeshes = [];
      ms.forEach((m) => {
        if (m.id.includes("N_Seoul_Tower_BrochureStand_primitive")) {
          arrayMeshes.push(m);
        }
      });
      const group = BABYLON.Mesh.MergeMeshes(
        arrayMeshes,
        false,
        true,
        false,
        false,
        true
      );
      group.id = "N_Seoul_Tower_Brochure_Group";
      scene.getMeshByID("BackgroundHelper").dispose();
      setIsSceneReady(true);
    };
    const ProgressCallback = () => {};

    WebGLUtil.Mesh.import(
      scene,
      define.RESOURCE.Booth.Tower,
      ProgressCallback,
      SuccessCallback
    );

    if (window.orientation == null) {
      scene.onPointerObservable.add((pointerInfo) => {
        switch (pointerInfo.type) {
          // 마우스 휠 움직일 경우
          case BABYLON.PointerEventTypes.POINTERWHEEL:
            // 마우스 휠 움직인 정도를 받아옴 (휠을 앞으로 밀면 +, 당기면 -)
            camera.fov -= pointerInfo.event.wheelDelta * 0.0005;
            if (camera.fov <= 0.5) camera.fov = 0.5;
            if (0.9 <= camera.fov) camera.fov = 0.9;
            break;
        }
      });
    }
  }, []);

  const onRender = useCallback((scene) => {}, []);

  useEffect(() => {
    if (seoulBoothSetError || isSeoulBoothApplied) {
      setOnLazyLoading(false);
    }
    return () => {
      if (isSeoulBoothApplied) {
        setIsSceneReady(false);
        setOnLazyLoading(true);
        setIsSeoulBoothApplied(false);
      }
    };
  }, [seoulBoothSetError, isSeoulBoothApplied]);

  useEffect(() => {
    if (eventInfoCompleted) {
      const hasSeoulBoothInfo = Object.keys(seoulBoothSet).length;
      if (hasSeoulBoothInfo) {
        if (sceneRef.current && isSceneReady && onLazyLoading) {
          for (const [k, v] of Object.entries(enabledMeshes)) {
            const { id, callback, material, notEnabledIf } = v;
            if (typeof notEnabledIf === "function" && notEnabledIf()) continue;
            const mesh = scene.getMeshByID(k);
            if (callback) {
              const actionManager = WebGLUtil.Event.setActionManager(scene);
              mesh.actionManager = actionManager;
              const action = WebGLUtil.Event.setExecuteCodeAction(
                BABYLON.ActionManager.OnPickTrigger,
                v.callback(seoulBoothSet[id])
              );
              WebGLUtil.Event.registerAction(mesh, action);
            }

            if (material) {
              const { id, type, albeldo = "" } = material;
              const url = seoulBoothSet[id];
              if (albeldo && url) {
                mesh.material.albedoTexture.updateURL(url);
              }
              url
                ? type === "diffuse" || type === "emissive"
                  ? WebGLUtil.Material.setTexture(mesh.material, {
                      type,
                      url,
                      scene,
                    })
                  : WebGLUtil.Mesh.makeMaterialToVideo(
                      sceneRef.current,
                      url,
                      mesh
                    )
                : null;

              if (url) {
                if (type === "video") {
                  mesh.material.emissiveTexture.vScale = 1;
                } else {
                  mesh.material.emissiveTexture.vScale = -1;
                }
              }
            }
          }
          setIsSeoulBoothApplied(true);
        }
      } else {
        setIsSeoulBoothApplied(true);
      }
    }
    return () => {};
  }, [seoulBoothSet, isSceneReady, onLazyLoading, eventInfoCompleted]);

  return (
    <div
      style={{
        overflow: "hidden",
        WebkitUserSelect: "none",
        WebkitTouchCallout: "none",
      }}
    >
      <BaseScene
        antialias={true}
        onSceneReady={onSceneReady}
        onRender={onRender}
        onLazyLoading={onLazyLoading}
        isSceneReady={isSceneReady}
      />
    </div>
  );
};

SeoulNTower.fragments = {
  seoulBoothSet: gql`
  fragment SeoulBoothFragment on Event {
    ${seoulBooth}
  }
  `,
};

export default SeoulNTower;
