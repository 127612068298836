import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import {
  toggleBookmark,
  boothExhibitionList,
  addStatisticCompanyVisit,
} from "../../../query/queries";
import { func } from "prop-types";

const BOOTH_LIST_INFO = gql`
  ${boothExhibitionList}
`;

const TOGGLE_BOOKMARK = gql`
  ${toggleBookmark}
`;

const ADD_STATISTIC_COMPANY_VISIT = gql`
  ${addStatisticCompanyVisit}
`;

const ExhibitorList = ({
  idx,
  categoryList = {},
  openCompanyInfo,
  close,
  loginType,
  boothId,
  refetch,
}) => {
  const [addStatisticCompanyVisit] = useMutation(ADD_STATISTIC_COMPANY_VISIT);
  const client = useApolloClient();
  const location = useLocation();
  const [
    toggleBookmark,
    {
      loading: isToggleBookmarkLoading,
      error: isToggleBookmarkError,
      data: toggleBookmarkData,
    },
  ] = useMutation(TOGGLE_BOOKMARK);
  const boothList = useRef(null);
  const [exhibitionData, setExhibitionData] = useState({});
  const [categoryFilter, setCategoryFilter] = useState({
    categoryList1: [],
    categoryList2: [],
    categoryList3: [],
  });
  const selectedCategoryFilter = {
    categoryList1: useRef(""),
    categoryList2: useRef(""),
    categoryList3: useRef(""),
  };
  const keyword = useRef("");
  const [page, setPage] = useState(+idx - 1);
  const [isToggled, setIsToggled] = useState(false);
  const history = useHistory();

  const getBoothListCache = async () => {
    // if (!boothList.current) {
    const data = await client.readQuery({
      query: BOOTH_LIST_INFO,
    });
    const { boothExhibitionList } = data;
    if (boothExhibitionList) {
      boothList.current = boothExhibitionList;
      filter(boothList.current);
    }
    // }
  };

  const prev = () => {
    if (page !== 0) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page < boothList.current.length - 1) {
      setPage((prev) => prev + 1);
    }
  };

  const refresh = async () => {
    try {
      setCategoryFilter({
        categoryList1: [],
        categoryList2: [],
        categoryList3: [],
      });
      Object.keys(selectedCategoryFilter).forEach((k) => {
        selectedCategoryFilter[k].current.value = "";
      });
      keyword.current.value = "";
    } catch (err) {
      console.error("refetch error ::", err);
    }
  };

  const search = () => {
    if (!keyword.current.value) {
      return filter();
    }
    const companyList =
      exhibitionData.companyList.filter((el) =>
        el.organization
          .toUpperCase()
          .includes(keyword.current.value.toUpperCase())
      ) || [];
    setExhibitionData((prev) => ({ ...prev, companyList }));
  };

  const visitBooth = (e) => {
    const { id: idForVisitBooth } = e.currentTarget;
    addStatisticCompanyVisit({ variables: { company_idx: +idForVisitBooth } });
    history.push(`/exhibition/booth/${idForVisitBooth}`);
    if (boothId) {
      window.location.reload();
    }
    close();
  };

  const bookmark = (e) => {
    e.stopPropagation();
    const { id: idForBookmark } = e.currentTarget;
    try {
      toggleBookmark({
        variables: {
          company_idx: Number(`${idForBookmark}`),
        },
        update: (cache, result) => {
          const { data } = result;
          if (data.toggleBookmark) {
            const { company_idx } = data.toggleBookmark;
            // 기존에 company_idx가 있을 경우만 수정
            cache.modify({
              id: cache.identify({
                id: `${page + 1}`,
                __typename: "BoothExhibition",
              }),
              fields: {
                companyList(currCompanyList, { readField }) {
                  const newCompanyList = [];
                  currCompanyList.forEach((c) => {
                    // const idx = readField("idx", c);
                    const isBookmarked = readField("is_bookmarked", c);
                    if (+c.idx === company_idx) {
                      isBookmarked === "Y"
                        ? newCompanyList.push({ ...c, is_bookmarked: "N" })
                        : newCompanyList.push({ ...c, is_bookmarked: "Y" });
                    } else {
                      newCompanyList.push(c);
                    }
                  });
                  return newCompanyList;
                },
              },
            });
          }
        },
      });
    } catch (err) {
      console.log("bookmark err ::", err);
    }
  };

  const renderOption = useCallback(
    (list = []) => {
      if (!list.length) return null;
      return list.map((l) => (
        <option key={l.idx} value={l.idx}>
          {l.name}
        </option>
      ));
    },
    [categoryList]
  );

  const onCategorySelect = (e) => {
    const { name, value } = e.currentTarget;
    const names = {
      categoryList1: () => {
        +value
          ? setCategoryFilter((prev) => ({
              ...prev,
              categoryList2:
                categoryList.categoryList2?.filter(
                  (v) => v.parent_lev === +value
                ) || [],
              categoryList3: [],
            }))
          : setCategoryFilter((prev) => ({
              ...prev,
              categoryList2: [],
              categoryList3: [],
            }));
      },
      categoryList2: () => {
        +value
          ? setCategoryFilter((prev) => ({
              ...prev,
              categoryList3:
                categoryList.categoryList3?.filter(
                  (v) => v.parent_lev === +value
                ) || [],
            }))
          : setCategoryFilter((prev) => ({
              ...prev,
              categoryList3: [],
            }));
      },
    };
    return typeof names[name] === "function" ? names[name]() : null;
  };

  const filter = async (boothList) => {
    if (boothList?.length) {
      const booth = boothList[page];
      if (booth?.companyList) {
        const { categoryList1, categoryList2, categoryList3 } =
          selectedCategoryFilter;
        let filteredCompanyList = booth.companyList.filter((c) => {
          // return (
          if (categoryList1.current.value) {
            if (c.category1 === categoryList1.current.value) {
              if (!categoryList2.current.value) {
                return true;
              }
            }
          }
          if (categoryList2.current.value) {
            if (c.category2 === categoryList2.current.value) {
              if (!categoryList3.current.value) {
                return true;
              }
            }
          }
          if (categoryList3.current.value) {
            if (c.category3 === categoryList3.current.value) {
              return true;
            }
          }
          if (keyword.current.value) {
            return c.organization
              .toUpperCase()
              .includes(keyword.current.value.toUpperCase());
          }

          // categoryList1.current.value &&
          //   (c.category1 === categoryList1.current.value) && categoryList2.current.value? false : true ||
          // categoryList2.current.value &&
          //   (c.category2 === categoryList2.current.value)  && categoryList3.current.value? false : true ||
          // (categoryList3.current.value &&
          //   c.category3 === categoryList3.current.value) ||
          // (keyword.current.value &&
          //   c.organization
          //     .toUpperCase()
          //     .includes(keyword.current.value.toUpperCase()))

          // );
        });

        setExhibitionData({
          name: booth.name,
          companyList:
            categoryList1.current.value ||
            categoryList2.current.value ||
            categoryList3.current.value
              ? isToggled
                ? sort(alphabetSort(filteredCompanyList.slice()))
                : alphabetSort(filteredCompanyList.slice())
              : isToggled
              ? sort(alphabetSort(booth.companyList.slice()))
              : alphabetSort(booth.companyList.slice()),
        });
      } else {
        setExhibitionData({
          name: booth.name,
          companyList: [],
        });
      }
    }
  };

  const alphabetSort = (companyList) => {
    return companyList.sort(function (a, b) {
      if (a.organization > b.organization) {
        return 1;
      }
      if (a.organization < b.organization) {
        return -1;
      }
      return 0;
    });
  };

  const sort = (companyList) => {
    let sortedCompanyList = [];
    const arr1 = [];
    const arr2 = [];

    companyList?.forEach((el) => {
      if (el.sort_order) {
        el.is_bookmarked === "Y" ? arr1.unshift(el) : arr1.push(el);
      } else {
        el.is_bookmarked === "Y" ? arr2.unshift(el) : arr2.push(el);
      }
    });

    sortedCompanyList = [...arr1, ...arr2];
    return sortedCompanyList;
  };

  const sortByBookMark = (e) => {
    e.stopPropagation();
    setIsToggled((prev) => !prev);
  };

  useEffect(() => {
    if (boothList.current) {
      filter(boothList.current);
    }
  }, [page, categoryFilter, isToggled]);

  useEffect(() => {
    getBoothListCache();
  }, []);

  useEffect(() => {
    if (!isToggleBookmarkLoading && toggleBookmarkData) {
      console.log("read after update");
      getBoothListCache();
    }

    if (isToggleBookmarkError) {
      console.error("mutation ToggleBookmark Error ::", isToggleBookmarkError);
    }
  }, [isToggleBookmarkLoading, isToggleBookmarkError]);

  useEffect(() => {
    // console.log("boothList", boothList);
    console.log("exhibitionData", exhibitionData);
  }, [exhibitionData]);

  return (
    <>
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__wrapper wide zoomIn">
          <div className="modal__header">
            <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button>
            <section className="modal__tit">
              <h2>
                <span>
                  <button className="prev" onClick={prev}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                  </button>
                </span>
                {exhibitionData.name}
                {/* {title} */}
                <span>
                  <button className="next" onClick={next}>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                  </button>
                </span>
              </h2>
            </section>
          </div>

          <div className="modal__content pd0">
            <div className="content-box">
              <div className="exhibitor-list">
                <div className="top">
                  <div className="row">
                    <button
                      className="refresh"
                      type="button"
                      onClick={refresh}
                      // onClick="window.location.reload()"
                    >
                      <ion-icon name="refresh"></ion-icon>
                    </button>
                    <div className="column">
                      <div className="col-1">
                        <select
                          ref={selectedCategoryFilter.categoryList1}
                          name="categoryList1"
                          id="categoryList1"
                          onChange={onCategorySelect}
                        >
                          {/* <optgroup label="Category1"> */}
                          <option value=""></option>
                          {Object.keys(categoryList).length
                            ? categoryList.categoryList1.length
                              ? renderOption(categoryList.categoryList1)
                              : null
                            : null}
                          {/* </optgroup> */}
                        </select>
                      </div>
                      <div className="col-1">
                        <select
                          ref={selectedCategoryFilter.categoryList2}
                          name="categoryList2"
                          id="categoryList2"
                          onChange={onCategorySelect}
                        >
                          {/* <optgroup label="Category2"> */}
                          <option value=""></option>
                          {categoryFilter.categoryList2.length
                            ? renderOption(categoryFilter.categoryList2)
                            : null}
                          {/* </optgroup> */}
                        </select>
                      </div>
                      <div className="col-1">
                        <select
                          ref={selectedCategoryFilter.categoryList3}
                          name="categoryList3"
                          id="categoryList3"
                          onChange={onCategorySelect}
                        >
                          {/* <optgroup label="Category3"> */}
                          <option value=""></option>
                          {categoryFilter.categoryList3.length
                            ? renderOption(categoryFilter.categoryList3)
                            : null}
                          {/* </optgroup> */}
                        </select>
                      </div>
                      <div className="col-2">
                        <button className="col-search-btn" onClick={search}>
                          <ion-icon name="search-outline"></ion-icon>
                        </button>
                        <input
                          ref={keyword}
                          type="text"
                          className="col-input-search"
                          placeholder="Company Name"
                        />
                      </div>
                    </div>
                  </div>
                  <section className="listing-exhibitor__title">
                    <ul className="listing-exhibitor__list">
                      <div className="listing-exhibitor__cell">No</div>
                      <div className="listing-exhibitor__cell">
                        Company Name
                      </div>
                      <div className="listing-exhibitor__cell">
                        Company Introduction
                      </div>
                      <div
                        className="listing-exhibitor__cell bkmk"
                        onClick={sortByBookMark}
                        style={{
                          cursor: exhibitionData?.companyList?.length
                            ? "pointer"
                            : "initial",
                        }}
                      >
                        Bookmark{" "}
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                      </div>
                      <div className="listing-exhibitor__cell">Booth</div>
                    </ul>
                  </section>
                </div>
                <div className="bottom">
                  <ul className="listing-exhibitor">
                    {Object.keys(exhibitionData).length
                      ? exhibitionData?.companyList?.map((c, i) => {
                          const {
                            idx: cIdx,
                            organization,
                            sort_order,
                            abstract,
                            is_bookmarked,
                          } = c;
                          const _openCompanyInfo = () =>
                            openCompanyInfo(c, idx);
                          return (
                            <li
                              className="listing-exhibitor__list"
                              key={`${cIdx}_${organization}`}
                            >
                              <div className="listing-exhibitor__cell">
                                <p>{i + 1}</p>
                              </div>
                              <div className="listing-exhibitor__cell">
                                <a
                                  className={
                                    sort_order
                                      ? "listing-exhibitor__active sponsor"
                                      : "listing-exhibitor__active"
                                  }
                                  onClick={_openCompanyInfo}
                                >
                                  <p>{organization}</p>
                                </a>
                              </div>
                              <div className="listing-exhibitor__cell">
                                <p className="intro">{abstract}</p>
                              </div>
                              <div className="listing-exhibitor__cell">
                                <div className="bookmark">
                                  {loginType !== "unlogin" &&
                                  is_bookmarked === "Y" ? (
                                    <button
                                      key={`${cIdx}_Y`}
                                      id={cIdx}
                                      type="button"
                                      // className={"bookmark-btn on"}
                                      className="bookmark-btn on"
                                      onClick={bookmark}
                                    >
                                      <ion-icon name="bookmark"></ion-icon>
                                    </button>
                                  ) : (
                                    <button
                                      key={`${cIdx}_N`}
                                      id={cIdx}
                                      type="button"
                                      // className={"bookmark-btn on"}
                                      className="bookmark-btn"
                                      onClick={bookmark}
                                    >
                                      <ion-icon name="bookmark"></ion-icon>
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div className="listing-exhibitor__cell">
                                <button
                                  id={cIdx}
                                  type="button"
                                  className="visit-btn"
                                  onClick={visitBooth}
                                >
                                  <span>Visit</span>
                                  <ion-icon name="arrow-forward"></ion-icon>
                                </button>
                              </div>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExhibitorList;
