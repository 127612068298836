import { useEffect, useState } from "react";
import { ASSETS_HOST } from "../config/define";

export const useProgressiveImage = (
  src = `${ASSETS_HOST}/membership/card_bg.png`,
  isCanvas = true
) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  useEffect(() => {
    if (!src) return;
    let imgType;

    imgType = src.slice(src.length - 3);
    if (imgType === "peg") {
      // it means this is jpeg extension
      imgType = "jpeg";
    }

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = src;
    img.onload = () => {
      console.log("imgType in onload::", imgType);

      if (isCanvas) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        context.drawImage(img, 0, 0);
        let dataUri;

        if (imgType === "jpg") {
          dataUri = canvas.toDataURL("image/jpg");
        } else if (imgType === "png") {
          dataUri = canvas.toDataURL("image/png");
        } else if (imgType === "jpeg") {
          dataUri = canvas.toDataURL("image/jpeg");
        } else {
          // no exception since i checked in aws uploader fn
          return;
        }
        setSourceLoaded(dataUri);
      } else {
        setSourceLoaded(src);
      }
    };
    img.onerror = (err) => {
      console.log("load failed :: ", src);
      console.log(err);
      console.log(err.message);
    };
  }, [src]);

  return sourceLoaded;
};
