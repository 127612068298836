import React from "react";

const PulsingAnimation = () => (
  <div className="pulsing_animation">
    <a href="#" className="blob-btn">
      <div className="ring">
        <span className="pulse pulse--1"></span>
        <span className="pulse pulse--2"></span>
      </div>
    </a>
  </div>
);

export default PulsingAnimation;
