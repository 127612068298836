import { useContext, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import { lobbySet, replayList, sessionListQuery } from "../query/queries";
import Lobby from "../pages/lobby/Lobby";
import Navbar from "../components/navbar/Navbar";
import IndoorExhibitionContainer from "../pages/indoor/IndoorExhibition";
import { UserLoginContext } from "../context/UserLoginContext";
import { LoadingContext } from "../context/LoadingContext";

const EVENT_INFO = gql`
  query ($language: LANGUAGE_TYPE) {
    event (language: $language)  {
      type
      main_dimension
      dimension_transform_active
      time_zone
      isLang
      language
      login_logo
      ...LobbyFragment
      ...NavbarFragment
      ...IndoorExhibitionContainerFragment
      eventActive {
        idx
        event_idx
        conference_active
        workshop_active
        exhibition_active
        open_stage_active
        theater_active
        seoul_booth_active
        lounge_active
        business_room_active
        outdoor_active
        event_banner_active
        all_active
        all_inactive_msg
        pt_survey_active
      }
      scheduleList {
        step
        schedule_date
        ${sessionListQuery} 
        isToday
      }
      ${replayList}
      speakerList {
          name,
          email,
          job_title,
          organization,
          country,
          biodata,
          photo_url,
          role,
      }
      theaterSet {
        theaterCategoryList {
          category_name
          cover_url
          sort_order
          theaterVodList {
            idx
            category_idx
            name
            cover_url
            video_url
            summary
            sort_order
          }
        }
      }
      eventBannerSet {
        idx
        event_idx
        banner_name
        image_url
        sort_order
        link
      }
    }
  }
  ${Lobby.fragments.lobbySet}
  ${Navbar.fragments.navigationSet}
  ${IndoorExhibitionContainer.fragments.indoorExhibitionSet}
  `;
// ...WorkshopFragment
// ...BusinessRoomFragment
// ...OpenStageFragment
// ...TheaterFragment
// ...SeoulBoothFragment

// ${WorkshopOne.fragments.workshopSet}
// ${BusinessRoom.fragments.businessRoomSet}
// ${OpenStage.fragments.openStageSet}
// ${Theater.fragments.theaterSet}
// ${SeoulNTower.fragments.seoulBoothSet}

export const useEventInfo = () => {
  const { isLogin } = useContext(UserLoginContext);
  const { isIntroLoading } = useContext(LoadingContext);
  const [eventInfo, setEventInfo] = useState({});
  const [dimension, setDimension] = useState({});
  const [eventInfoCompleted, setEventInfoCompleted] = useState(false);
  const [getEventInfo, { loading, error, data, refetch }] = useLazyQuery(
    EVENT_INFO,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const refetchEventInfo = async (lang) => {
    setEventInfoCompleted(false);
    refetch(lang ? { language: lang } : null);
  };

  const dimensionTransform = async (dimension) => {
    setDimension(dimension);
  };

  useEffect(() => {
    // const _getEventInfo = async () => {
    if (!isIntroLoading) {
      getEventInfo();
    }
    // };
    // _getEventInfo();
    return () => {
      if (Object.keys(eventInfo).length) {
        setEventInfo({});
      }
    };
  }, [isIntroLoading]);
  console.log("EVENT_INFO: ", data);

  useEffect(() => {
    if (!loading && data) {
      setEventInfo(data.event || {});
      setDimension(data.event.main_dimension || "");
      setEventInfoCompleted(true);
    }
  }, [loading, data]);

  useEffect(() => {
    if (error) {
      console.log(
        "EVENT_INFO QUERY ERROR ::",
        error.graphQLErrors,
        error.message
      );
    }
  }, [error]);

  useEffect(() => {
    if (isLogin && !Object.keys(eventInfo).length && refetch) {
      refetch();
    }
  }, [isLogin]);

  return {
    refetchEventInfo,
    eventInfo,
    dimension,
    dimensionTransform,
    error,
    eventInfoCompleted,
  };
};

export default useEventInfo;
