import { createContext, useState, useContext, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { boothExhibitionList, companyCategorySetList } from "../query/queries";

const BoothContext = createContext();

const BOOTH_LIST_INFO = gql`
  ${boothExhibitionList}
`;

const CATEGORY_LIST = gql`
  ${companyCategorySetList}
`;

const BoothContextProvider = (props) => {
  const { children } = props;
  const { loading, error, data, refetch } = useQuery(BOOTH_LIST_INFO);
  const {
    loading: categoryListLoading,
    error: categoryListError,
    data: categoryList,
    refetch: refetchCategoryList,
  } = useQuery(CATEGORY_LIST);
  const [companyInfo, setCompanyInfo] = useState({});
  // const boothListRef = useRef([]);
  // const setBoothList = (list) => (boothListRef.current = list);

  // useEffect(() => {
  //   if (!loading && data) {
  //     setBoothList(data.boothExhibitionList || []);
  //   }
  // }, [loading, data]);

  useEffect(() => {
    if (error) {
      // error message
      console.error("query BOOTH_LIST_INFO error ::", error);
    }
  }, [error]);

  const value = {
    boothList: data?.boothExhibitionList || [],
    companyInfo,
    categoryList: categoryList?.companyCategorySetList || {},
    setCompanyInfo,
    refetch,
  };

  return (
    <BoothContext.Provider value={value}>{children}</BoothContext.Provider>
  );
};

export { BoothContext, BoothContextProvider };
