import React from "react";
import { ASSETS_HOST } from "../../config/define";

const Brochure = ({ brochureList, close }) => {
  const openBrochure = (e) => {
    const { url } = e.currentTarget.dataset;
    if (url) window.open(url, "_blank");
  };
  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper wide zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>Brochure</h2>
          </section>
        </div>
        <div className="modal__content">
          <div className="content-box">
            <div className="listing-brochure">
              <ul className="listing-brochure__list">
                {brochureList
                  ? brochureList.map((b) => {
                      return (
                        <li
                          key={b.download_url}
                          className="listing-brochure__item"
                        >
                          <a
                            className="listing-brochure__link"
                            data-url={b.download_url}
                            onClick={openBrochure}
                          >
                            <img
                              className="listing-brochure__img"
                              src={
                                b.cover_url ||
                                `${ASSETS_HOST}/img/brochure_cover.png`
                              }
                              alt="Download Brochure"
                            />
                          </a>
                          <p className="listing-brochure__title">
                            {/* {brochure.title} */}
                          </p>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brochure;
