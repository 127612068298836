import { createContext, useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { loadingSet } from "../query/queries";

const LOADING_INFO = gql`
    query {
        event {
            ${loadingSet}
        }
    }
`;
const LoadingContext = createContext();

const LoadingContextProvider = ({ children }) => {
  const { loading, error, data } = useQuery(LOADING_INFO);
  const [introInfo, setIntroInfo] = useState({});

  useEffect(() => {
    if (!loading && data) {
      const { loadingSet } = data.event;
      setIntroInfo(loadingSet || {});
    }
    return () => {};
  }, [loading, data]);

  useEffect(() => {
    if (error) {
      // error message
      console.error("query lodingSet error ::", error);
    }
  }, [error]);

  const value = {
    introInfo,
    isIntroLoading: loading,
  };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingContextProvider };
