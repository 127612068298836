import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import define, { ASSETS_HOST } from "../../config/define";
import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import BaseScene from "../../components/webgl/BaseScene";
import WebGLUtil from "../../util/webgl";
import { ModalPortalContext } from "../../context/ModalContext";
import { LoadingContext } from "../../context/LoadingContext";
import { lobbySet } from "../../query/queries";
import { virtualPlaceNavs } from "../../config/virtualPlaceNavigation";
import { UserLoginContext } from "../../context/UserLoginContext";
import CustomLoadingScreen from "../../components/webgl/CustomLoadingScreen";

const Lobby = ({
  isLang,
  loginType,
  names,
  error: lobbySetError,
  lobbySet,
  eventInfoCompleted,
  setStamp,
}) => {
  return <></>;
};

export default Lobby;

Lobby.fragments = {
  lobbySet: gql`
    fragment LobbyFragment on Event {
      ${lobbySet}
    }
  `,
};
