import React, { useEffect } from "react";
import ReactMoment from "react-moment";

const Messenger = ({
  openMessenger,
  setOpenMessenger,
  roomList,
  requestRoomList,
  joinRoom,
  currentRoom,
  inRoom,
  exitRoom,
  roomHistory,
  sendMyMessage,
  setMyMessage,
  myMessage,
  myId,
  myName,
}) => {
  const hasCurrentRoom = Object.keys(currentRoom).length;

  useEffect(() => {
    if (openMessenger) requestRoomList();
    // console.log("Messenger inRoom ::", inRoom);
    return () => {
      setOpenMessenger(false);
      // console.log("Messenger unloaded. openMessenger :: ", openMessenger); // not reference
    };
  }, []);

  // useEffect(() => {
  //   if (roomList.length && isDirectJoin) {
  //     joinRoom(roomList.filter((r) => r.room_code === isDirectJoin) || []);
  //     setDirectJoin("");
  //   }
  // }, [roomList, isDirectJoin]);
  return (
    <div className="govent-push__modal-bg">
      <div className="govent-push__message-wrap">
        <div className="govent-push__message-header">
          {inRoom && (
            <button
              type="button"
              className="govent-push__message-prev"
              onClick={exitRoom}
            >
              ←
            </button>
          )}
          <button
            type="button"
            className="govent-push__message-close"
            onClick={(e) => {
              exitRoom();
              setOpenMessenger(false);
            }}
          ></button>
          {inRoom && hasCurrentRoom ? (
            <div className="govent-push__message-listname">
              {currentRoom.room_title.split("_").filter((v) => v !== myName)[0]}
            </div>
          ) : null}
          {inRoom === false && (
            <div className="govent-push__message-listname">대화방 목록</div>
          )}
        </div>
        {/* 방 리스트 */}
        {inRoom === false && (
          <div className="govent-push__message-con">
            <ul className="govent-push__message-ul">
              {/* {JSON.stringify(roomList)} */}
              {roomList &&
                roomList.map((room, index) => {
                  const {
                    type,
                    room_code,
                    last_iat,
                    last_message,
                    host_id,
                    guest_name,
                    host_name,
                    room_title,
                  } = room;
                  // console.log("room ::", room);
                  return (
                    <li
                      key={"r_" + room_code}
                      className={`govent-push__message-li ${
                        type === "notice" && "notice"
                      }`}
                      onClick={(e) => {
                        joinRoom(room);
                      }}
                    >
                      <p className="govent-push__message-name">
                        {room_title.split("_").filter((v) => v !== myName)[0]}
                      </p>
                      <p className="govent-push__message-date">
                        {/* {room_code !== "notice" && last_iat && (
                          <ReactMoment format="YYYY-MM-DD HH:mm">
                            {last_iat}
                          </ReactMoment>
                        )} */}
                        {last_iat && (
                          <ReactMoment format="YYYY-MM-DD HH:mm">
                            {last_iat}
                          </ReactMoment>
                        )}
                      </p>
                      <p className="govent-push__message-txt">
                        {/* {room_code !== "notice" && last_message} */}
                        {last_message}
                      </p>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
        {/* 방 입장 */}
        {inRoom && (
          <div className="govent-push__message-con">
            <div className="govent-push__log" id="govent_push_chatLog">
              {/* {console.log("roomHistory ::", roomHistory)} */}
              {roomHistory &&
                roomHistory.map((item, index) => {
                  // console.log("item ::", item);
                  const { _client, body } = item;
                  const {
                    iat,
                    message,
                    messageId,
                    room_code,
                    room_title,
                    user_id,
                    user_name,
                    sender_id,
                    sender_name,
                    role,
                  } = body;

                  let roleClass = "";

                  if (role === "staff") {
                    roleClass = "govent-push__log-item--staff";
                  } else {
                    const isMine = sender_id === myId;
                    if (isMine) {
                      roleClass = "govent-push__log-item--own";
                    }
                  }
                  return (
                    <ul
                      className={"govent-push__log-item " + roleClass}
                      key={"m_" + index}
                    >
                      <p className="govent-push__log-userName">{sender_name}</p>
                      <li className="govent-push__log-con">
                        <p>
                          {message}
                          <span className="govent-push__log-time">
                            <ReactMoment format="HH:mm">{iat}</ReactMoment>
                          </span>
                        </p>
                      </li>
                    </ul>
                  );
                })}
            </div>
            <div className="govent-push__send">
              {hasCurrentRoom ? (
                <input
                  className="govent-push__send-input"
                  type="text"
                  name="myMessage"
                  placeholder={
                    currentRoom.is_read_only === "Y"
                      ? "채팅이 불가능한 채널입니다."
                      : "메세지를 입력하세요."
                  }
                  autoComplete="off"
                  maxLength="100"
                  disabled={currentRoom.is_read_only === "Y"}
                  value={myMessage}
                  onChange={(e) => {
                    // console.log("v ::", e.target.value);
                    setMyMessage(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      sendMyMessage(myMessage);
                    }
                  }}
                />
              ) : null}

              <button className="govent-push__send-btn" onClick={sendMyMessage}>
                <svg
                  version="1.1"
                  id="layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 44 44"
                  style={{ enableBackground: "new 0 0 44 44" }}
                >
                  <path
                    style={{ fill: "#CCCCCC" }}
                    d="M33,12.186H13.833c-2,0-3.833,1.667-3.833,3.833v16.833c0,0.833,0.833,1.167,1.5,0.833l5.333-3.833
	c0.5-0.333,1-0.5,1.5-0.5h11.833c2,0,3.833-1.667,3.833-3.833v-12.5C34,12.519,33.5,12.186,33,12.186z M28.333,23.853H17
	c-0.5,0-1-0.5-1-1s0.5-1,1-1h11.333c0.5,0,1,0.5,1,1C29.333,23.519,28.833,23.853,28.333,23.853z M28.333,19.353H17
	c-0.5,0-1-0.5-1-1c0-0.5,0.5-1,1-1h11.333c0.5,0,1,0.5,1,1S28.833,19.353,28.333,19.353z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// export default Messenger;
export default React.memo(Messenger, (prevProps, nextProps) => {
  // prevProps.users === nextProps.users;
  // console.log("prevProps ::", prevProps);
  // console.log("nextProps ::", nextProps);
  return (
    prevProps.roomList === nextProps.roomList &&
    prevProps.inRoom === nextProps.inRoom &&
    prevProps.roomHistory === nextProps.roomHistory &&
    prevProps.myMessage === nextProps.myMessage
  );
});
