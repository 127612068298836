/*
project root의 .env 환경변수 참고.
*/
// console.log(
//   "process.env.REACT_APP_PSA_API_URL ",
//   process.env.REACT_APP_PSA_API_URL
// );
const CDN_HOST = `${process.env.REACT_APP_PUBLIC_RESOURCES_URL}`;
export const ASSETS_HOST = `${process.env.REACT_APP_PUBLIC_ASSETS_URL}`;
const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const define2D = {
  API_BASE_URL: `${process.env.NEXT_PUBLIC_CLIENT_API_BASE_URL}`,
  RESOURCE: {
    Lobby: {
      Style1: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/Lobby01.jpg`,
      Style2: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/Lobby04.jpg`,
      Style3: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/Lobby03.jpg`,
      Style4: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/LOBBY_2D_04.jpeg`,
    },
    ExhibitionHall: {
      Style1: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/ExhibitionHall01.jpg`,
      Style2: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/ExhibitionHall03.jpg`,
      Style3: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/ExhibitionHall02.jpg`,
    },
    Booth: {
      Style1: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/Booth_01.jpg`,
      Style2: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/Booth_02.jpg`,
      Icon: {
        info: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/booth_info_icon.png`,
      },
    },
    ConferenceHall: {
      Style1: `https://d1yl2ylhnnn0dz.cloudfront.net/file/GOVENT/ConferenceHall01.jpg`,
    },
  },
  API: {
    AUTH_LOGIN: {
      method: "post",
      url: `${API_BASE_URL}/auth/login`,
    },
  },
};

export default define2D;
