import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

const SEND_COMPANY_MESSAGE = gql`
  mutation sendCompanyMessage($input: sendCompanyMessageInput) {
    sendCompanyMessage(input: $input) {
      idx
    }
  }
`;

const BizCardComponent = ({ close, openAlert, company_idx, initialState }) => {
  const [form, setForm] = React.useState(initialState);

  const onInput = (e) => {
    const { name, value } = e.target;
    // console.log("name", name);
    // console.log("value", value);

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // console.log("form ", form);

  const [sendCompanyMessage, { data, loading }] = useMutation(
    SEND_COMPANY_MESSAGE,
    {
      onError: (error) => {
        console.log("error :: ", error);
        window.alert("Your request has been failed ::", error);
      },
      onCompleted: (data) => {
        window.alert("Your Business Card has been sent.");
        close();
        console.log("data :: ", data);
      },
    }
  );

  const confirm = () => {
    for (const key in form) {
      if (!form[key].length) return window.alert(`${key} is required`);
    }
    const input = { ...form, company_idx: Number(company_idx) };
    // console.log("input :: ", input);

    sendCompanyMessage({ variables: { input } });

    // console.log("loading :: ", loading);
    // console.log("data :: ", data);
  };

  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small mb-top hauto zoomIn">
        <div className="modal__header">
          <button onClick={close} className="close">
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h3>Send Your Message</h3>
            <p className="lg">
              Please send your message with your contact details.
            </p>
          </section>
        </div>
        <div className="modal__content">
          <div className="content-box">
            <div className="biz-card">
              <form className="biz-card__box">
                <div>
                  <label htmlFor="name">
                    <span className="biz-card__tit">
                      Name<em>*</em>
                    </span>
                    <input
                      type="text"
                      className="biz-card__input"
                      name="name"
                      required
                      onChange={onInput}
                      value={form.name}
                    />
                  </label>
                  <label htmlFor="e-mail">
                    <span className="biz-card__tit">
                      E-mail<em>*</em>
                    </span>
                    <input
                      type="email"
                      className="biz-card__input"
                      name="email"
                      required
                      onChange={onInput}
                      value={form.email}
                    />
                  </label>
                  <label htmlFor="phone">
                    <span className="biz-card__tit">Phone</span>
                    <input
                      type="text"
                      className="biz-card__input"
                      name="phone"
                      onChange={onInput}
                      value={form.phone}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="Message">
                    <span className="biz-card__tit">Message</span>
                    <textarea
                      value={form.message}
                      onChange={onInput}
                      className="biz-card__textarea"
                      name="message"
                    ></textarea>
                  </label>
                </div>
              </form>
            </div>
            <div className="biz-card__button">
              <button onClick={close} type="button" className="cancel">
                CANCEL
              </button>
              <button onClick={confirm} type="button" className="send">
                SEND
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const BizCard = (props) => {
  const { user, close, openAlert, company_idx } = props;
  console.log("user ", user);
  console.log("props ", props);
  if (user && Object.keys(user).length > 0) {
    console.log("called ! ");
    initialState.name = user.name || "";
    initialState.email = user.email || "";
    initialState.phone = user.phone || "";
  }

  return (
    <BizCardComponent
      company_idx={company_idx}
      openAlert={openAlert}
      close={close}
      initialState={initialState}
    />
  );
};

export default BizCard;

// import React, { useRef } from "react";
// import { gql } from '@apollo/client';
// import { useQuery } from '@apollo/client';

// const BizCard = ({ user = {}, loginType, close, openAlert }) => {
//   const isUnlogin = loginType === "unlogin";
//   const formState = {
//     name: {
//       isDirty: useRef(""),
//       value: useRef(""),
//     },
//     company: {
//       isDirty: useRef(""),
//       value: useRef(""),
//     },
//     email: {
//       isDirty: useRef(""),
//       value: useRef(""),
//     },
//     job_title: {
//       isDirty: useRef(""),
//       value: useRef(""),
//     },
//     organization: {
//       isDirty: useRef(""),
//       value: useRef(""),
//     },
//     country: {
//       isDirty: useRef(""),
//       value: useRef(""),
//     },
//   };
//   const nameRef = useRef(null);

//   const onInput = (e) => {
//     console.log(e.target);
//   };

//   console.log("user ", user);

//   const confirm = () => {
//     if (Object.keys(formState).every((k) => formState[k].current.length)) {
//       openAlert({ bool: true, msg: "Your Business Card has been sent." });
//       close();
//     }
//   };

//   return (
//     <div className="modal">
//       <div className="modal__overlay"></div>
//       <div className="modal__wrapper small zoomIn">
//         <div className="modal__header">
//           <button className="close" onClick={close}>
//             <ion-icon name="close"></ion-icon>
//           </button>
//           <section className="modal__tit">
//             <h3>Business Card</h3>
//             <p>Provide your business card htmlFor continuous consultation.</p>
//           </section>
//         </div>
//         <div className="modal__content">
//           <div className="content-box">
//             <div className="biz-card">
//               {isUnlogin ? (
//                 <>
//                   <h4>
//                     <p
//                       className="biz-card__name"
//                       ref={nameRef}
//                       onInput={onInput}
//                       contentEditable
//                       suppressContentEditableWarning
//                     >
//                       Name :
//                     </p>
//                     <p
//                       className="biz-card__company"
//                       contentEditable
//                       suppressContentEditableWarning
//                     >
//                       Organization :
//                     </p>
//                   </h4>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">E-mail (ID)</p>
//                     <p
//                       className="biz-card__txt"
//                       contentEditable
//                       suppressContentEditableWarning
//                     >
//                       abc@example.com
//                     </p>
//                   </div>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">Job Title</p>
//                     <p
//                       className="biz-card__txt"
//                       contentEditable
//                       suppressContentEditableWarning
//                     >
//                       Mananger
//                     </p>
//                   </div>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">Organization</p>
//                     <p
//                       className="biz-card__txt"
//                       contentEditable
//                       suppressContentEditableWarning
//                     >
//                       Organization
//                     </p>
//                   </div>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">Country</p>
//                     <p
//                       className="biz-card__txt"
//                       contentEditable
//                       suppressContentEditableWarning
//                     >
//                       the Republic of Korea
//                     </p>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <h4>
//                     <p className="biz-card__name">{user.name}</p>
//                     <p className="biz-card__company">{user.organization}</p>
//                   </h4>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">E-mail (ID)</p>
//                     <p className="biz-card__txt">{user.email}</p>
//                   </div>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">Job Title</p>
//                     <p className="biz-card__txt">{user.job_title}</p>
//                   </div>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">Organization</p>
//                     <p className="biz-card__txt">{user.organization}</p>
//                   </div>
//                   <div className="biz-card__box">
//                     <p className="biz-card__tit">Country</p>
//                     <p className="biz-card__txt">{user.country}</p>
//                   </div>
//                 </>
//               )}
//             </div>
//             <div className="biz-card__button">
//               <button type="button" className="cancel" onClick={close}>
//                 CANCEL
//               </button>
//               <button type="button" className="confirm" onClick={confirm}>
//                 CONFIRM
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BizCard;
