import React, { useContext } from "react";
import { useParams } from "react-router";
import { BoothContext, BoothContextProvider } from "../../context/BoothContext";
import { ModalPortalContext } from "../../context/ModalContext";
import Booth from "../../pages/booth/Booth";

const BoothContainer = (props) => {
  const { triggerModal } = useContext(ModalPortalContext);
  const { id } = useParams();
  return (
    <div style={{ height: "100%" }}>
      <BoothContextProvider>
        <Booth id={id} {...props} triggerModal={triggerModal} />
      </BoothContextProvider>
    </div>
  );
};

export default BoothContainer;
