/*
project root의 .env 환경변수 참고.
*/
console.log(
  "process.env.REACT_APP_PSA_API_URL ",
  process.env.REACT_APP_PSA_API_URL
);
const CDN_HOST = `${process.env.REACT_APP_PUBLIC_RESOURCES_URL}`;
export const ASSETS_HOST = `${process.env.REACT_APP_PUBLIC_ASSETS_URL}`;
const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const define = {
  API_BASE_URL: `${process.env.NEXT_PUBLIC_CLIENT_API_BASE_URL}`,
  RESOURCE: {
    Lobby: {
      glb: {
        day: `${CDN_HOST}/lobby/SeoulMap_day.glb`,
        night: `${CDN_HOST}/lobby/SeoulMap_night.glb`,
      },
    },
    Psa: {
      glb: `${CDN_HOST}/psa/Business_Room.glb`,
    },
    Opening: {
      glb: `${CDN_HOST}/opening/Openstage.glb`,
    },
    Indoor: {
      glb: `${CDN_HOST}/indoor/Coex.glb`,
    },
    Outdoor: {
      glb: `${CDN_HOST}/outdoor/NodeulIsland.glb`,
    },
    Booth: {
      Tower: `${CDN_HOST}/seoul_booth/N_Seoul_Tower.glb`,
      Platinum: `${CDN_HOST}/booth/Booth_A.glb`,
      Gold: `${CDN_HOST}/booth/Booth_B.glb`,
      Silver: `${CDN_HOST}/booth/Booth_C.glb`,
    },
    Lounge: {
      glb: `${CDN_HOST}/lounge/lounge.glb`,
    },
    Conference: {
      glb: `${CDN_HOST}/conference/Conference_Room.glb`,
    },
    Workshop: {
      glb: `${CDN_HOST}/workshop/WorkShop_Room.glb`,
    },
    Theater: {
      glb: `${CDN_HOST}/theater/TheaterHall.glb`,
    },
  },
  API: {
    AUTH_LOGIN: {
      method: "post",
      url: `${API_BASE_URL}/auth/login`,
    },
  },
};

export default define;
