import { gql } from "@apollo/client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CompanyInfo from "../../components/exhibition/CompanyInfo";
import ExhibitionNavigator from "../../components/exhibition/ExhibitionNavigator";
import ExhibitorList from "../../components/exhibition/type/ExhibitorList";
import { BoothContext, BoothContextProvider } from "../../context/BoothContext";
import { ModalPortalContext } from "../../context/ModalContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import useResize from "../../hooks/useResize";
import { indoorExhibition } from "../../query/queries";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import define2D from "../../config/define2D";

const Booth01Pusher = styled.div`
  position: absolute;
  top: 478px;
  left: 20px;
  width: 570px;
  height: 300px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;

  .logoUrl {
    position: relative;
    left: 6px;
    top: 4px;
    width: 562px;
    height: 52px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    position: relative;
    // background-color: rgba(255, 0, 0, 0.5);
    left: 20px;
    top: 63px;
    width: 50px;
    height: 80px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth02Pusher = styled.div`
  position: absolute;
  top: 450px;
  left: 610px;
  width: 760px;
  height: 450px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;

  .logoUrl {
    position: relative;
    left: 3px;
    top: 9px;
    width: 712px;
    height: 69px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 20px;
    top: 100px;
    width: 69px;
    height: 116px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const Booth03Pusher = styled.div`
  position: absolute;
  top: 480px;
  left: 1375px;
  width: 520px;
  height: 300px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;

  .logoUrl {
    position: relative;
    left: 4px;
    top: 4px;
    width: 441px;
    height: 52px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .boothBanner {
    position: relative;
    // background-color: rgba(255, 0, 0, 0.5);
    left: 15px;
    top: 61px;
    width: 50px;
    height: 81px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;

const IndoorExhibition2DStyle2 = ({
  eventInfoCompleted,
  indoorExhibitionSet,
  loginType,
  error: indoorExhibitionSetError,
  boothPusher,
  boothList,
}) => {
  const { user } = useContext(UserLoginContext);
  // const { boothList, categoryList, companyInfo, refetch } =
  //   useContext(BoothContext);
  const { triggerModal } = useContext(ModalPortalContext);

  useEffect(() => {
    if (indoorExhibitionSetError) {
      setOnLazyLoading(false);
      setIsLoading(false);
      setStamp("exhibition_booth_visit");
    }
  }, [indoorExhibitionSetError]);

  return (
    <>
      <img
        alt="exhibition BG image"
        src={define2D.RESOURCE.ExhibitionHall.Style2}
        className="lobby__background"
      />
      <Booth01Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[0]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[0]?.companyBooth[0]
                    ?.exhibition_logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[0]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
      </Booth01Pusher>
      <Booth02Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[1]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[1]?.companyBooth[0]
                    ?.exhibition_logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[1]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
      </Booth02Pusher>
      <Booth03Pusher
        data-id={
          boothList?.companyList
            ? boothList?.companyList[2]?.companyBooth[0]?.company_idx
            : null
        }
        onClick={boothPusher}
      >
        <div className="logoUrl">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[2]?.companyBooth[0]
                    ?.exhibition_logo_url
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
        <div className="boothBanner">
          <img
            src={
              boothList?.companyList
                ? boothList?.companyList[2]?.companyBooth[0]?.booth_banner
                : "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            }
          />
        </div>
      </Booth03Pusher>
    </>
  );
};

const IndoorExhibitionContainer = (props) => {
  return (
    <BoothContextProvider>
      <IndoorExhibition2DStyle2 {...props} />
    </BoothContextProvider>
  );
};

export default IndoorExhibitionContainer;
