import { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router";
import ConcurrentSession from "../components/session/ConcurrentSession";
import { getTodaySchedule } from "../context/functions";
import { ModalPortalContext } from "../context/ModalContext";

const useSession = ({ scheduleList, eventInfoCompleted, worker, mainLang }) => {
  const { isModalOpen, triggerModal } = useContext(ModalPortalContext);
  const [todaySchedule, setTodaySchedule] = useState({});
  const [sessions, setSessions] = useState({});
  const [selectedSession, setSelectedSession] = useState({});
  const sessionRef = useRef({});
  const selectedSessionRef = useRef({});
  const conCurrentSessionIdx = useRef([]);
  const [selectedPT, setSelectedPT] = useState({});
  const [isUseSessionLoading, setIsUseSessionLoading] = useState(true);
  const isSessionEventPosted = useRef(false);
  const isPTEventPosted = useRef(false);
  const isModalOpenRef = useRef(isModalOpen);
  const location = useLocation();
  const locationRef = useRef(location);

  const setConCurrentSessionIdx = (v) => (conCurrentSessionIdx.current = v);
  const showConcurrentSession = (sessions) => {
    if (!conCurrentSessionIdx.current.length) return;
    triggerModal({
      bool: true,
      props: {
        sessionList: sessions[
          locationRef.current.pathname.slice(
            1,
            locationRef.current.pathname.length
          )
        ].filter((s) =>
          conCurrentSessionIdx.current.filter(
            (v) => v.idx === s.idx && v.isLang === s.isLang
          )
        ),
        setSelectedSession,
      },
      content: {
        title: "",
        Component: (props) => <ConcurrentSession {...props} />,
        hasOwnContainer: true,
      },
    });
  };

  useEffect(() => {
    if (scheduleList.length) {
      setTodaySchedule(getTodaySchedule(scheduleList || []));
    }
  }, [scheduleList]);

  useEffect(() => {
    if (eventInfoCompleted) {
      if (Object.keys(todaySchedule).length) {
        const session = {};
        if (todaySchedule.sessionList) {
          todaySchedule.sessionList.forEach((s) => {
            if (!s.place_type) return null;
            const place_type = s.place_type.split("_").join("");
            if (!session[place_type]) return (session[place_type] = [s]);
            session[place_type].push(s);
          });
          setSessions(session);
          setIsUseSessionLoading(false);
        }
      } else {
        setIsUseSessionLoading(false);
      }
    }
  }, [todaySchedule, eventInfoCompleted]);

  useEffect(() => {
    locationRef.current = location;
    if (
      mainLang &&
      location.pathname &&
      (isSessionEventPosted.current || isPTEventPosted.current)
    ) {
      Promise.all([
        setSelectedPT({}),
        setSelectedSession({}),
        setConCurrentSessionIdx([]),
      ]).then((_) => {
        worker.postMessage({
          type: "SLEEP",
          payload: {
            immediate: true,
            done: true,
          },
        });
        isSessionEventPosted.current = false;
        isPTEventPosted.current = false;
      });
    }
  }, [mainLang, location]);

  useEffect(() => {
    const sessionKeys = Object.keys(sessions) || [];
    if (worker && sessionKeys.length && locationRef.current) {
      sessionRef.current = sessions;
      if (!isSessionEventPosted.current) {
        const bool = sessionKeys.includes(
          locationRef.current.pathname.slice(
            1,
            locationRef.current.pathname.length
          )
        );
        if (bool) {
          worker.setOnMessageAction("SESSION_OPEN", (sIdxs) => {
            if (isModalOpenRef.current) return null;
            if (
              !(
                conCurrentSessionIdx.current.length === sIdxs.length &&
                conCurrentSessionIdx.current.every(
                  (v, i) =>
                    sIdxs[i].idx === v.idx && sIdxs[i].isLang === v.isLang
                )
              )
            ) {
              if (sIdxs.length >= 2) {
                setConCurrentSessionIdx(sIdxs);
                showConcurrentSession(sessionRef.current);
              } else if (
                sIdxs.length === 1 &&
                +sIdxs[0].idx !== selectedSession.idx
              ) {
                const session =
                  sessionRef.current[
                    locationRef.current.pathname.slice(
                      1,
                      locationRef.current.pathname.length
                    )
                  ].filter((s) => s.idx === sIdxs[0].idx)[0] || {};
                setSelectedSession(session);
                setConCurrentSessionIdx([]);
              } else if (!sIdxs.length && Object.keys(selectedSession).length) {
                setSelectedSession({});
                setConCurrentSessionIdx([]);
              }
            }
          });

          worker.postMessage({
            type: "SESSION_OPEN",
            payload: {
              immediate: true,
              scheduleDate: todaySchedule.schedule_date,
              session: JSON.stringify(
                sessions[
                  locationRef.current.pathname.slice(
                    1,
                    locationRef.current.pathname.length
                  )
                ]
              ),
            },
          });
          isSessionEventPosted.current = true;
        }
      }
    }
  }, [worker, sessions, selectedSession, location, mainLang]);

  useEffect(() => {
    const hasSelectedSession = Object.keys(selectedSession).length;
    const hasSelectedPT = Object.keys(selectedPT).length;
    if (worker && hasSelectedSession && !hasSelectedPT) {
      selectedSessionRef.current = selectedSession;
      if (!isPTEventPosted.current) {
        worker.setOnMessageAction("PRESENTATION_OPEN", (pIdx) => {
          if (pIdx.length && selectedPT.idx !== pIdx[0]) {
            setSelectedPT((prev) => {
              return (
                selectedSessionRef.current.presentationList?.filter(
                  (v) => v.idx === pIdx[0]
                )[0] || {}
              );
            });
          }
        });

        worker.postMessage({
          type: "PRESENTATION_OPEN",
          payload: {
            immediate: true,
            presentationList: JSON.stringify(selectedSession.presentationList),
          },
        });
        isPTEventPosted.current = true;
      }
    }
  }, [
    worker,
    selectedPT,
    selectedSession.presentationList,
    location,
    mainLang,
  ]);

  useEffect(() => {
    return () => {
      setSelectedPT({});
      setSessions({});
      setTodaySchedule({});
    };
  }, []);

  return {
    todaySchedule,
    sessions,
    selectedSession,
    selectedPT,
    setSelectedSession,
    isUseSessionLoading,
  };
};

export default useSession;
