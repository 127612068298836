import React, { useState } from "react";

const SessionReplay = ({ title, children, replayList, close }) => {
  const [selectedVod, setSelectedVod] = useState({ title: "", url: "" });
  const play = (e) => {
    setSelectedVod({
      title: e.currentTarget.dataset.title,
      url: e.currentTarget.dataset.action,
    });
  };

  const exit = () => {
    setSelectedVod({
      title: "",
      url: "",
    });
  };

  return (
    <>
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__wrapper wide zoomIn">
          <div className="modal__header">
            <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button>
            <section className="modal__tit">
              <h2>{title}</h2>
            </section>
          </div>
          <div className="modal__content session-replay">
            <div className="content-box">
              <ul className="sr__list">
                {replayList && replayList.length
                  ? replayList.map((v, i) => {
                      return (
                        <li
                          key={v.title}
                          className="sr__item"
                          data-title={v.title}
                          data-action={v.action_url}
                          onClick={play}
                        >
                          {/* todo v.action_url */}
                          <a className="sr__link">
                            <div className="sr__img">
                              <img src={v.cover_url} alt="Video Thumbnail" />
                            </div>
                            <div className="sr__info">
                              <h2 className="sr__info--title">{v.title}</h2>
                              <p className="sr__info--speaker">
                                <ion-icon name="person"></ion-icon>
                                {v.speaker ? v.speaker.name : ""}
                              </p>
                              <small className="sr__info--desc">
                                {v.note || ""}
                              </small>
                            </div>
                          </a>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {selectedVod.url
        ? React.cloneElement(children, {
            close: exit,
            selectedVod,
          })
        : null}
    </>
  );
};

export default SessionReplay;
