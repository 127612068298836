import React, { useEffect } from "react";

const CommonNav2D = ({ navs, setIsClicked, triggerModal }) => {
  //   useEffect(() => {
  //     setIsRefetching((prev) => ({ ...prev, common: false }));
  //   }, []);

  return navs.length
    ? navs.map((v) => (
        <li key={v.name} className="nav__item--2D" onClick={v.onClick}>
          <span className="nav__link--2D">
            <ion-icon name={v.icon} class="nav__link--icon--2D"></ion-icon>
            <span className="nav__link--txt--2D">{v.name}</span>
          </span>
        </li>
      ))
    : null;
};

export default CommonNav2D;
