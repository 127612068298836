import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { UserLoginContext } from "./UserLoginContext";

const SocketContext = createContext();

const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(undefined);
  const { getToken, user } = useContext(UserLoginContext);
  const [currentRoom, setCurrentRoom] = useState({});

  const namespace = process.env.REACT_APP_PUSH_GROUP;
  const PUSH_HOST = process.env.REACT_APP_PUSH_HOST;

  const connectServer = async () => {
    const options = {
      auth: {
        token: getToken(),
      },
      transports: ["websocket"],
    };
    // socket에서 token 의 유효성을 한번 더 검사해서 disconnect 시킴.
    // 이 PUSH_GROUP 별로 푸시가 묶음. 다른 Event랑 중복되지 않도록 조심할 것. 중복되면 다른 사람들 거 다 보일 수도 있음.
    const socket = io.connect(`${PUSH_HOST}` + "/" + namespace, options);

    socket.on("connect", () => {
      console.log("socket connected ::", socket.id);
      // setIsActive(true);
      // setMyStatus("on");
    });

    socket.on("auth", (payload) => {
      console.log("auth event fired.", payload);
    });

    socket.on("disconnect", (message) => {
      console.log("disconnect from server.", message);
      // setIsActive(false);
      // setMyStatus("off");
    });
    // 다른 사용자가 사이트를 나감.
    socket.on("disconnectUser", (payload) => {
      // console.log("exit ::", payload);
      // removeUser(payload.body);
    });

    setSocket(socket);
  };

  const disconnectServer = () => {
    if (socket) socket.disconnect();
  };

  const enter = (room) => setCurrentRoom(room);
  const leave = () => setCurrentRoom({});

  useEffect(() => {
    if (Object.keys(user).length) {
      connectServer();
    }
    return () => {
      disconnectServer();
    };
  }, [user]);

  const value = {
    socket,
    currentRoom,
    setCurrentRoom,
  };
  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
