import React, { useState, useEffect, useCallback } from "react";
import CustomLoadingScreen from "../components/webgl/CustomLoadingScreen";

const CustomLoadingContext = React.createContext(null);

const CustomLoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(null);

  function customLoadingScreen() {}
  customLoadingScreen.prototype.displayLoadingUI = function () {
    if (!isLoading) setIsLoading(true);
  };
  customLoadingScreen.prototype.hideLoadingUI = function () {
    setIsLoading(false);
  };

  const value = {
    customLoadingScreen: new customLoadingScreen(),
  };
  return (
    <CustomLoadingContext.Provider value={value}>
      {isLoading ? <CustomLoadingScreen /> : null}
      {children}
    </CustomLoadingContext.Provider>
  );
};

export { CustomLoadingProvider, CustomLoadingContext };
