const isEmpty = (str) => {
  if (str === undefined || str === null) return true;
  if (typeof str === "string") {
    if (
      str.trim() === "undefined" ||
      str.trim() === "null" ||
      str.trim() === ""
    ) {
      return true;
    }
  }

  return false;
};

const isEmptyObject = (object) => {
  if (object === undefined) return true; // empty.
  return Object.keys(object).length === 0 && object.constructor === Object;
};

const randomNumber = () => {
  const a = Math.ceil(Math.random() * 10);
  const b = Math.ceil(Math.random() * 10);
  const c = Math.ceil(Math.random() * 10);
  const d = Math.ceil(Math.random() * 10);

  const s = a + "" + b + "" + c + "" + d;
  return s.substring(0, 4);
};

const mapToArr = (map) => {
  const arr = [];
  for (let [key, value] of map) {
    arr.push(value);
  }

  return arr;
};

const canUseDOM = () => {
  if (typeof window === "undefined") return false;
  return true;
};

const getChildValue = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

// colmns에 들어갈 selectList에 들어갈 key값을 바꿔주는 함수 (바꿀배열,원본key값[idx],원본key값[title혹은 name 등])
const selListChange = (arr, codeVal, labelVal) => {
  const propListArr = arr;
  const selList = [];
  propListArr.forEach((item) => {
    selList.push({ label: item[labelVal], code: item[codeVal] });
  });
  return selList;
};

const hasGQLError = (error) => {
  if (error) {
    // console.log(JSON.stringify(error));
    // UNAUTHENTICATED -> token 없음. ForbiddenError -> token의 권한 이슈 -> login again.
    // error 검색 순서 :  networkError(from Context) -> graphQLErrors (from Query or Mutation) -> User Input Error
    if (error.networkError) {
      const { statusCode, result } = error.networkError;
      const { errors } = result;
      const { message, extensions } = errors[0];
      const { code } = extensions;
      // console.log("code ::", code);
      let errorMessage = message;
      if (code === "FORBIDDEN" || code === "UNAUTHENTICATED") {
        errorMessage += "\n다시 로그인해주세요.";
      }
      window.alert(errorMessage);
      return true;
    }

    if (error.graphQLErrors) {
      const {
        extensions: { code },
        message,
      } = error.graphQLErrors[0];
      if (code === "FORBIDDEN" || code === "UNAUTHENTICATED") {
        window.alert(message + "\n로그인을 다시 하여야 합니다.");
        return true;
      } else if (
        code === "BAD_USER_INPUT" ||
        code === "INTERNAL_SERVER_ERROR"
      ) {
        window.alert(message);
        return true;
      }
    }
  }
  return false;
};

// 쿠키값 가져오기
const getCookieValue = (key) => {
  let cookieKey = key + "=";
  let result = "";
  const cookieArr = decodeURIComponent(document.cookie).split(";");

  for (let i = 0; i < cookieArr.length; i++) {
    if (cookieArr[i][0] === " ") {
      cookieArr[i] = cookieArr[i].substring(1);
    }

    if (cookieArr[i].indexOf(cookieKey) === 0) {
      result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
      return result;
    }
  }
  return result;
};

// 쿠키값 삭제
const deleteCookie = (key) => {
  document.cookie = key + `=; path=/; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
};

const isIE = () => !!navigator.userAgent.match(/Trident.*rv/);

// 유튜브 vod_url을 cover_url로 변경
const imageUrl = (vod_url) => {
  const imgUrl = "https://img.youtube.com/vi/{video_id}/0.jpg";
  const urlRe = /\{video\_id\}/gi;

  const vodUrl = vod_url.split("/");

  if (vodUrl[2] === "www.youtube.com") {
    const urlId = vodUrl[vodUrl.length - 1].split("?");

    let cover_url = "";
    if (urlId[1]) {
      cover_url = imgUrl.replace(urlRe, urlId[0]);
    } else {
      cover_url = imgUrl.replace(urlRe, urlId);
    }

    return cover_url;
  }
  return null;
};

// 비메오 다운로드 URL -> 임베드 URL
const vimeoVodUrl = (url) => {
  let vodUrl = null;

  if (url?.indexOf("vimeo") > -1) {
    const regExp =
      /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
    const matchs = url.match(regExp);

    if (matchs?.[6]) {
      const vimeoId = matchs[6];
      vodUrl = `https://player.vimeo.com/video/${vimeoId}?autoplay=1&title=0&byline=0&portrait=0`;

      return vodUrl;
    }
  }
  return vodUrl;
};

// 유튜브, vimeo URL 이미지,동영상 변환
const urlChange = async (url) => {
  console.log("url before", url);
  let imgUrl = null;
  let vodUrl = null;

  if (url?.indexOf("youtu") > -1) {
    const regExp =
      /^.*(?:(?:youtu\.be\/|v\/|e\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const matchs = url.match(regExp);

    const idCheck = matchs?.[1].split("/");

    if (matchs?.[1] && idCheck?.length < 2) {
      const youtubeId = matchs[1];

      imgUrl = `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
      vodUrl = `https://www.youtube.com/embed/${youtubeId}?rel=0&autoplay=1`;

      return { imgUrl, vodUrl };
    }
  }

  if (url?.indexOf("vimeo") > -1) {
    const regExp =
      /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
    const matchs = url.match(regExp);

    if (matchs?.[6]) {
      const vimeoId = matchs[6];

      const ajaxUrl = `https://vimeo.com/api/v2/video/${vimeoId}.json`;

      try {
        const { data } = await axios({
          url: ajaxUrl,
          dataType: "jsonp",
          jsonp: "callback",
        });

        imgUrl = data?.[0]?.thumbnail_large;
        vodUrl = `https://player.vimeo.com/video/${vimeoId}?autoplay=1&title=0&byline=0&portrait=0`;
      } catch (err) {
        console.log(err);
      }

      return { imgUrl, vodUrl };
    }
  }
  return { imgUrl, vodUrl };
};

const util = {
  isIE,
  isEmpty,
  isEmptyObject,
  randomNumber,
  mapToArr,
  canUseDOM,
  getChildValue,
  selListChange,
  hasGQLError,
  getCookieValue,
  deleteCookie,
  imageUrl,
  vimeoVodUrl,
  urlChange,
};

export default util;
