import React from "react";

const VOD = ({
  title,
  videoInfo = {},
  videoList = [],
  play,
  goToPrev,
  close,
}) => {
  const { video_url, name, summary } = videoInfo;
  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper video zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <button className="back" onClick={goToPrev}>
            <ion-icon name="arrow-back"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>{name ? name : videoList[0]?.name}</h2>
          </section>
        </div>
        <div className="modal__content video-player">
          <div className="content-box">
            <div className="video-player__content">
              <div className="video-player__video">
                <iframe
                  title="video"
                  width="100%"
                  height="100%"
                  src={
                    video_url
                      ? video_url
                      : videoList[0]?.video_url
                      ? videoList[0]?.video_url
                      : "https://player.vimeo.com/video/465656144?autoplay=1&loop=1&title=0&byline=0&portrait=0"
                  }
                  preload="auto"
                  className="embed-responsive-item"
                  allow="camera; microphone; autoplay; fullscreen"
                ></iframe>
              </div>
              <div className="video-player__details">
                <p className="tit">{name ? name : videoList[0]?.name}</p>
                <span className="desc">
                  {summary ? summary : videoList[0]?.summary}
                </span>
              </div>
            </div>
            <div className="video-player__list">
              <ul>
                {videoList.length
                  ? videoList.map((v) => {
                      if (
                        v.name === name ||
                        (!name && v.name === videoList[0]?.name)
                      )
                        return "";
                      return (
                        <li
                          key={v.name}
                          className="video-player__list--item"
                          onClick={() => play(title, v, videoList)}
                        >
                          <img src={v.cover_url} alt="Video Thumbnail" />
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VOD;
