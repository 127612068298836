import React from "react";
import termType from "./terms";

const UserPolicy = React.memo(
  ({ type, consent, setAgreement, close }) => {
    const agree = () => (
      setAgreement((prev) => ({ ...prev, [`${type}_yn`]: true })), close()
    );
    const term = termType[type] || {};
    return (
      <div className={type ? "modal popup" : "modal popup hidden"}>
        <div className="modal__overlay"></div>
        <div className="modal__wrapper small mb-top zoomIn">
          <div className="modal__header">
            <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button>
            <section className="modal__tit">
              <h2>{term.header}</h2>
            </section>
          </div>
          <div className="modal__content">
            <div className="explan_txt">
              {/* <p className="explan_txt--em">{term.title}</p> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: consent ? consent[type] : null,
                }}
              ></div>
            </div>
            <button type="button" className="agree-btn" onClick={agree}>
              동의
            </button>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => prev.type === next.type
);

export default UserPolicy;
