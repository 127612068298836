import { createContext, useState, useContext, useEffect, useRef } from "react";
import util from "../util/index";
import { ModalPortalContext } from "./ModalContext";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Login from "./../components/common/modal/contents/Login";
import { event } from "../query/queries";

const UserLoginContext = createContext();

const EVENT_LOGINSET = gql`
  ${event}
`;

const GET_ME = gql`
  query {
    me {
      idx
      name
      email
      job_title
      organization
      country
      role
      privacy_yn
      user_yn
      psa_available
    }
  }
`;

const UserLoginContextProvider = ({ children }) => {
  const { triggerModal } = useContext(ModalPortalContext);
  const [getMyInfo, { loading, called, data, error, refetch }] = useLazyQuery(
    GET_ME,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const {
    loading: loginsetLoading,
    data: loginsetData,
    error: loginsetError,
  } = useQuery(EVENT_LOGINSET, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const [user, _setUser] = useState({});
  const [isLogin, setIsLogin] = useState(false);

  const getLoginStatus = () => isLogin;

  const getToken = () => util.getCookieValue("clientToken");

  const setUser = (user) => _setUser(user);

  const getUser = () => user;

  const logout = async () => {
    await util.deleteCookie("clientToken");
    setUser({});
    setIsLogin(false);
  };

  const showLoginModal = (loginType, loginLogo) =>
    triggerModal({
      bool: true,
      props: {
        getMyInfo: called ? refetch : getMyInfo,
        setIsLogin,
        setUser,
        loginType,
        loginLogo,
        loginsetData,
      },
      content: {
        title: "LOGIN",
        Component: (props) => {
          return <Login {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton: false,
    });

  useEffect(() => {
    if (!loading) {
      if (data) {
        setUser(data.me || {});
        // date.me.role === company일 때 PSA 로그인 API 호출
        // psa api call here
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (error) {
      console.log(`/me error ::`, error.message);
      logout().then((_) => {
        if (!window.location.pathname.includes("/exhibition/booth")) {
          showLoginModal("");
        }
      });
    }
  }, [error]);

  useEffect(() => {
    if (isLogin) {
      called ? refetch() : getMyInfo();
    }
  }, [isLogin, called]);

  const value = {
    showLoginModal,
    getMyInfo,
    getToken,
    getLoginStatus,
    setUser,
    getUser,
    logout,
    refetch,
    setIsLogin,
    user,
    isLogin,
    loading,
    error,
  };

  return (
    <UserLoginContext.Provider value={value}>
      {children}
    </UserLoginContext.Provider>
  );
};

export { UserLoginContext, UserLoginContextProvider };
