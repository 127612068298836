import React, { useState, useEffect, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import util from "../../../../util";
import InformationAgreement from "./InformationAgreement";
import { consent } from "../../../../query/queries";

export const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword($input: changeUserPasswordInput) {
    changeUserPassword(input: $input) {
      privacy_yn
      user_yn
    }
  }
`;
export const CONSENT = gql`
  ${consent}
`;

const initialForm = {
  current_password: "",
  new_password1: "",
  new_password2: "",
  privacy_yn: "",
  user_yn: "",
};
const MyInfo = ({
  user,
  privacy_consent,
  user_consent,
  openAlert,
  close,
  refetch,
  children,
}) => {
  const [type, setPolicyType] = useState("");
  const [agreements, setAgreement] = useState({
    use: false,
    privacy: false,
    marketing: false,
  });
  const [form, setForm] = React.useState(initialForm);
  const [message, setMessage] = useState({ code: null, error: "" });
  const [changeUserPasswordQL] = useMutation(CHANGE_USER_PASSWORD, {
    onError: (error) => {
      console.log("changePcoUserPasswordQL error ::", error);
      openAlert({ bool: true, msg: error.message });
      //   if (util.isEmpty(error.message)) {
      //     const hasError = util.hasGQLError(error);
      //   }
    },
    onCompleted: (data) => {
      console.log("changePcoUserPasswordQL data ::", data);

      setForm((prev) => {
        return {
          ...prev,
          current_password: "",
          new_password1: "",
          new_password2: "",
        };
      });
      setMessage({ error: "" });
      openAlert({
        bool: true,
        msg: `Password has been changed
비밀번호 수정이 완료되었습니다.`,
        type: "success",
      });
      refetch();
    },
  });
  const {
    loading: isConsentLoading,
    error,
    data: consentData,
  } = useQuery(CONSENT);

  const showAgreementPolicy = (e) => {
    e.preventDefault();
    const { dataset } = e.currentTarget;
    setPolicyType(dataset.type || "");
  };

  const closeAgreementPolicy = () => setPolicyType("");

  const onChange = (e) => {
    const { id, name, value, type } = e.target;
    let v = value;
    if (type === "checkbox") {
      v = e.target.checked ? true : false;
    }
    setForm({
      ...form,
      [name]: v,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!form.current_password || !form.new_password1 || !form.new_password2) {
      openAlert({
        bool: true,
        msg: `Please check the password.
비밀번호를 입력해주세요.`,
      });
      return null;
    }

    if (!form.user_yn || form.user_yn === "N") {
      openAlert({
        bool: true,
        msg: `The agreement to the User Terms and Privacy Policy is required.
이용약관 및 개인정보 수집 및 이용에 동의해주세요.`,
      });
      return null;
    }

    if (form.current_password === form.new_password1) {
      openAlert({
        bool: true,
        msg: `Password has been changed
비밀번호 수정이 완료되었습니다.`,
        type: "success",
      });
      return null;
    }

    if (form.new_password1 !== form.new_password2) {
      openAlert({
        bool: true,
        msg: `Password confirmation doesn't match.
재확인 비밀번호가 일치하지 않습니다.`,
      });
      return null;
    }

    changeUserPasswordQL({
      variables: {
        input: {
          current_password: form.current_password,
          new_password1: form.new_password1,
          new_password2: form.new_password2,
          privacy_yn: form.privacy_yn ? "Y" : "N",
          user_yn: form.user_yn ? "Y" : "N",
        },
      },
    });
  };

  useEffect(() => {
    // console.log("user", user); //privacy_yn, user_yn
    if (Object.keys(user).length) {
      setForm({
        ...initialForm,
        privacy_yn: user.privacy_yn,
        user_yn: user.user_yn,
      });
    }
  }, [user]);

  const clearForm = () => setForm(initialForm);

  return (
    <>
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__wrapper zoomIn">
          <div className="modal__header">
            <button className="close" onClick={close}>
              <ion-icon name="close"></ion-icon>
            </button>
            <section className="modal__tit">
              <h2>나의 정보</h2>
            </section>
          </div>
          <div className="modal__content">
            <div className="content-box">
              <div className="mypage-wrap">
                <div className="container">
                  <h3>나의 프로필</h3>
                  <div className="text__box">
                    <p className="text__box--tit">이름</p>
                    <p className="text__box--txt">{user.name}</p>
                  </div>
                  <div className="text__box">
                    <p className="text__box--tit">이메일(아이디)</p>
                    <p className="text__box--txt">{user.email}</p>
                  </div>
                  <div className="text__box">
                    <p className="text__box--tit">회사명</p>
                    <p className="text__box--txt">{user.organization}</p>
                  </div>
                  <div className="text__box">
                    <p className="text__box--tit">직위/직책</p>
                    <p className="text__box--txt">{user.job_title}</p>
                  </div>

                  {/* <div className="text__box">
                    <p className="text__box--tit">Country</p>
                    <p className="text__box--txt">{user.country}</p>
                  </div> */}
                </div>
                <div className="container">
                  <h3>비밀번호 변경</h3>
                  <form className="change-pw" action="#" method="GET">
                    <div className="text__box">
                      <label htmlFor="current_password">
                        <p>현재 비밀번호</p>
                        <input
                          id="current_password"
                          type="password"
                          name="current_password"
                          onChange={onChange}
                          value={form.current_password}
                          placeholder="현재 비밀번호를 입력해 주세요."
                        />
                      </label>
                    </div>
                    <div className="text__box">
                      <label htmlFor="new_password1">
                        <p>새로운 비밀번호</p>
                        <input
                          id="new_password1"
                          type="password"
                          name="new_password1"
                          placeholder="새로운 비밀번호를 입력해 주세요."
                          value={form.new_password1}
                          onChange={onChange}
                        />
                      </label>
                    </div>
                    <div className="text__box">
                      <label htmlFor="new_password2">
                        <p>비밀번호 확인</p>
                        <input
                          id="new_password2"
                          type="password"
                          name="new_password2"
                          onChange={onChange}
                          value={form.new_password2}
                          placeholder="새로운 비밀번호를 재입력해 주세요."
                        />
                      </label>
                    </div>
                    <div className="change-pw__button">
                      <button
                        type="button"
                        className="clearform"
                        onClick={clearForm}
                      >
                        양식 지우기
                      </button>
                      <button
                        type="button"
                        className="confirm"
                        onClick={submit}
                      >
                        확인
                      </button>
                    </div>
                    <p className="error-msg hidden">
                      Please Check Your Account Or Password.
                    </p>
                  </form>
                </div>
                <div className="container">
                  {/* <h3>Consent to receive and use marketing information</h3> */}
                  <div className="policy-wrap">
                    <label htmlFor="user_yn" className="policy">
                      {form.user_yn === "Y" ? (
                        <input
                          type="checkbox"
                          className="user_yn_el"
                          name="user_yn"
                          id="use"
                          onChange={onChange}
                          defaultChecked={user.user_yn === "Y" ? true : false}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          className="user_yn_el"
                          name="user_yn"
                          id="use"
                          onChange={onChange}
                          defaultChecked={user.user_yn === "Y" ? true : false}
                        />
                      )}
                      <b>&#91;필수&#93;</b>
                      <span>
                        <a
                          className="terms"
                          data-type="use"
                          onClick={showAgreementPolicy}
                        >
                          &ensp;필수동의 항목에 대한 개인정보 수집·이용 동의
                        </a>
                      </span>
                    </label>
                    <label htmlFor="privacy_yn" className="policy">
                      {form.privacy_yn === "Y" ? (
                        <input
                          type="checkbox"
                          className="privacy_yn_el"
                          name="privacy_yn"
                          id="policy"
                          onChange={onChange}
                          required
                          defaultChecked={
                            user.privacy_yn === "Y" ? true : false
                          }
                          // value={form.privacy_yn}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          className="privacy_yn_el"
                          name="privacy_yn"
                          id="policy"
                          onChange={onChange}
                          required
                          defaultChecked={
                            user.privacy_yn === "Y" ? true : false
                          }
                          // value={form.privacy_yn}
                        />
                      )}
                      <b>&#91;선택&#93;</b>
                      <span>
                        <a
                          className="privacy"
                          data-type="privacy"
                          onClick={showAgreementPolicy}
                        >
                          &ensp;선택동의 항목에 대한 개인정보 수집·이용 동의
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {React.cloneElement(children, {
        close: closeAgreementPolicy,
        setAgreement,
        type,
        consent: {
          privacy: consentData ? consentData.event.privacy_consent : "",
          use: consentData ? consentData.event.user_consent : "",
        },
      })}
    </>
  );
};

export default MyInfo;
