import React from "react";
import { ASSETS_HOST } from "../../../../config/define2D";

const CounselorList = ({
  counselors,
  selectCounselor,
  // selectedCounselorIdx,
  close,
}) => {
  // const {active_yn, company_idx, idx, meeting_url, name} = counselors?.CounselorList[?];
  return (
    <div className="modal" style={{ zIndex: 10000 }}>
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            {/* {alert.type === "success" ? (
              <>
                <img
                  className="success"
                  src={`${ASSETS_HOST}/img/icon_success.png`}
                  alt="success"
                />
                <h2>Success</h2>
              </>
            ) : (
              <>
                <img
                  className="alert"
                  src={`${ASSETS_HOST}/img/icon_alert.png`}
                  alt="alert"
                />
                <h2>Alert</h2>
              </>
            )} */}
            <>
              {/* <img
                className="alert"
                src={`${ASSETS_HOST}/img/icon_alert.png`}
                alt="alert"
              /> */}
              <h2>Meeting</h2>
            </>
          </section>
        </div>
        <div className="modal__content">
          <div className="content-box">
            {/* <pre className="alert-msg">{alert.msg}</pre> */}
            <table className="meeting_list_table">
              <thead>
                <tr>
                  <th className="meeting_list_font_size">room name</th>
                  <th className="meeting_list_font_size">button</th>
                </tr>
              </thead>
              <tbody>
                {counselors?.counselorList?.map((el, i) => (
                  <tr key={i}>
                    <td className="meeting_list_font_size">{el.name}</td>
                    <td
                      className={
                        el.active_yn == "Y" ? "meeting_able" : "meeting_disable"
                      }
                    >
                      <a data-id={el.idx} onClick={selectCounselor}>
                        상담하기
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounselorList;
