import React from "react";

const Event = ({ eventBannerSet, close }) => {
  return (
    <div className="modal popup moderator" id="moderator">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small mb-top zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h3>Event</h3>
          </section>
        </div>
        <div className="modal__content event" style={{ padding: "0" }}>
          <div className="content-box">
            {eventBannerSet.map((bannerSet, index) => {
              const {idx, event_idx, image_url, link, banner_name, sort_order } = bannerSet
              if(!image_url) {
                return
              }
              return (
                <img
                  style={{cursor: link ? "pointer" : "default"}}
                  width="100%"
                  height="100%"
                  alt="event description"
                  // onClick={() => window.open("https://forms.gle/U9JkYwLHn8vnqmQeA", "구글폼")}
                  onClick={() => {
                    if(!link) {
                      return
                    }
                    window.open(link)
                  }}
                  src={
                    image_url
                    // "/assets/event/open_event_banner.png"
                    // eventImageUrl ||
                    // "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
