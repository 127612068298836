import React from "react";

const Intro = ({ next }) => {
  return (
    <div className="modal__content hd-dsc-l mbs">
      <div className="content-box">
        <div className="plus-seoul">
          <div className="membership">
            <h3 className="membership__header">
              <span className="ir_so">Membership Card's Header</span>
            </h3>
            <main className="membership__content">
              <aside className="membership__photo inactive"></aside>
              <div className="membership__profile">
                <div className="mb-profile">
                  <span className="name">Name</span>
                  <p className="kor">패트리샤 하이스미스</p>
                  <p className="eng">Patricia Highsmith</p>
                  <div className="add-date">Date of issue : 2021-08-30</div>
                </div>
              </div>
            </main>
            <footer className="membership__footer"></footer>
          </div>
          <div className="mb-hand"></div>
          <div className="mb-event">
            <ul className="mb-event__list">
              <li className="mb-event__item hangeul">
                <span className="ir_pm">Step 01</span>
              </li>
              <li className="mb-event__item smile">
                <span className="ir_pm">Step 02</span>
              </li>
              <li className="mb-event__item share">
                <span className="ir_pm">Step 03</span>
              </li>
            </ul>
          </div>
          <div className="picture-btn">
            <button type="button" onClick={next}>
              Get Yours Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
