import React, { useState, useRef } from "react";
import moment from "moment";
import * as html2canvas from "html2canvas";

const PreviewPicture = ({ next, photo, name, openAlert }) => {
  const [savedPhotos, setSavedPhotos] = useState({
    front: null,
  });
  const date = moment.utc().format();
  const local = moment.utc(date).local().format("YYYY-MM-DD");

  const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  };

  const savePhoto = () => {
    const photoIdPreview = document.getElementById("hiddenCardFront");
    console.log("photoIdPreview ::", photoIdPreview);
    html2canvas(photoIdPreview, {
      allowTaint: true,
      backgroundColor: "rgba(0,0,0,0)",
      // width: 960,
      // height: 610,
      ignoreElements: (node) => {
        return node.tagName === "ION-ICON";
      },
    }).then((canvas) => {
      console.log("canvas ::", canvas);
      const imgData = canvas.toDataURL("image/png");
      downloadURI(imgData, "Virtual_Seoul_Membership.png");
      // setSavedPhotos({ ...savedPhotos, front: imgData });
    });
  };

  return (
    <div className="modal__content hd-dsc-l mbs">
      <div className="content-box">
        <div className="plus-seoul">
          <div className="membership step2">
            <h3 className="membership__header">
              <span className="ir_so">Membership Card's Header</span>
            </h3>
            <main className="membership__content">
              <button
                style={
                  photo
                    ? {
                        backgroundImage: `url(${photo})`,
                        backgroundSize: "cover",
                      }
                    : null
                }
                className={`membership__photo ${photo ? "" : "inactive"}`}
                onClick={next}
              ></button>
              <div className="membership__profile sc-fzoJus ifGyeJ">
                <label>
                  <p className="name">Name</p>
                  <input
                    type="text"
                    className={`mb-name ${name.kr.length > 0 ? "off" : ""}`}
                    placeholder="Please enter your name."
                    readOnly
                    // defaultValue={name.kr}
                    onClick={() => next(4)}
                  />
                  <div className="membership__name">
                    <p className="kor">{name.kr}</p>
                    <p className="eng">{name.en}</p>
                  </div>
                </label>
                <div className="add-date">Date of issue : {local}</div>
              </div>
            </main>
            <footer className="membership__footer"></footer>
            <div
              className={
                name.kr && name.en && photo
                  ? "download-btn"
                  : "download-btn hidden"
              }
            >
              <button type="button" onClick={savePhoto}>
                Download & Share SNS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPicture;
