import React, { useContext, useEffect } from "react";

const InformationAgreement = ({
  is_agreed_privacy_policy,
  is_agreed_network_policy,
  onChange,
}) => {
  return (
    <div className="inputAgreement">
      <h3>
        <span>네트워킹 라운지 정보 공개 동의서</span>
      </h3>
      <br />
      <div>
        <div className="messge-box">
          <p style={{ fontWeight: "600" }}>
            네트워킹 라운지 개인정보 공개 동의서​
          </p>
          <br />
          <br />
          &nbsp;귀하의 개인정보를 The 11th Korea Healthcare Congress 2020 온라인
          플랫폼 네트워킹 라운지 내에서 타인이 조회 또는 열람하는 것에
          동의합니다.
          <br />
          <br />
          ※ 제공되는 개인정보 사항
          <br />
          - 이름, 소속, 직함, 연락처 등
          <br />
          <br />
          네트워킹 라운지 개인정보 공개에 동의하지 않으실 수 있습니다. 다만,
          동의 거부 시 네트워킹 라운지 서비스 이용이 부분적으로 제한될 수
          있습니다.
          <br />
          <br />
          - 온라인 플랫폼에 접속하더라도 네트워킹 라운지의 참가자 디렉토리에서
          노출되지 않음
          <br />- 다른 참가자에게 본인 정보를 제공 불가
        </div>
        <div className="privacy form">
          {/* todo: radios 클래스 네임 변경 */}
          <div className="agreement__input">
            <label htmlFor="is_agreed_privacy_policy">
              <input
                type="checkbox"
                id="is_agreed_privacy_policy"
                name="is_agreed_privacy_policy"
                checked={is_agreed_privacy_policy === "Y" ? true : false}
                onChange={onChange}
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                  appearance: "auto",
                }}
              />
              <span className="radio-text">
                By checking this box, you agree to our End User Terms and
                Privacy Policy​
              </span>
            </label>
          </div>
          <div>
            <label htmlFor="is_agreed_network_policy">
              <input
                type="checkbox"
                id="is_agreed_network_policy"
                name="is_agreed_network_policy"
                checked={is_agreed_network_policy === "Y" ? true : false}
                onChange={onChange}
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                  appearance: "auto",
                }}
              />
              <span className="radio-text">
                (Option) 마케팅 및 광고활용 동의​
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationAgreement;
