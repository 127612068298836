import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";

const WebCamContainer = ({ openAlert, setPhoto, prev }) => {
  const [hasCam, sethasCam] = useState(false);
  const webcamRef = useRef(null);
  const videoConstraints = {
    width: 324,
    height: 380,
    facingMode: "user",
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0]; // TODO : ie 11 지원 확인 필요.
    const { size, type } = file;
    if (size > 10000000)
      return openAlert({
        bool: true,
        msg: "the file size is up 10MB",
      });
    if (type.includes("image") === false) {
      openAlert({ bool: true, msg: "only image format is allowed" });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPhoto(reader.result);
      prev();
    };
  };

  const capture = () => {
    if (!hasCam) return;
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      openAlert({
        bool: true,
        msg: "Oops! Please take the picture again.",
      });
      return;
    }
    setPhoto(imageSrc);
    prev();
  };

  const detectWebcam = () => {
    const md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return sethasCam(false);
    md.enumerateDevices().then((devices) => {
      sethasCam(devices.some((device) => "videoinput" === device.kind));
    });
  };

  const setUserMediaError = (e) => {
    console.log("user media error :: ", e);
    sethasCam(false);
  };

  useEffect(() => {
    detectWebcam();
  }, []);

  return (
    <div className="modal__content hd-dsc-l mbs">
      <div className="content-box">
        <div className="plus-seoul">
          <div className="membership step3">
            {hasCam ? (
              <div style={{ position: "relative" }} className="camera-box">
                <Webcam
                  audio={false}
                  width="324px"
                  height="380px"
                  ref={webcamRef}
                  screenshotQuality="1"
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  onUserMediaError={setUserMediaError}
                />
              </div>
            ) : (
              <div
                style={{ position: "relative" }}
                className="camera-box not"
              ></div>
            )}
            <h3 className="mb-desc">
              Please adjust the guideline and press the filming button.
            </h3>
            <div className="take-btn">
              <button className="on" onClick={capture}>
                Take a Picture
              </button>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple=""
                type="file"
                style={{ display: "none" }}
                onChange={handleChangeFile}
              />
              <label htmlFor="contained-button-file" className="upload">
                <ion-icon name="cloud-upload-outline"></ion-icon> Upload Picture
              </label>
            </div>
            <p className="camera-msg">
              If you don't have a camera, upload a picture from your device.
              <br />
              We only accept image files of 210X246px, 10MB or less.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebCamContainer;
